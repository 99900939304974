import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DataGrid from 'components/molecules/DataGrid';
import FiltersBar from 'components/molecules/FiltersBar';
import { getSyncStatusButtonText, isEntityNonSynced } from 'utils/sync-status-helper';
import ActionButton from 'components/atoms/ActionButton';
import type { CashflowItem, SyncDetails } from 'types/api';
import SyncStatusInfo from 'components/molecules/SyncStatusInfo';
import { useSearchParams } from 'react-router-dom';
import { getCashflowsFilters, getCashflowsSortModel } from 'utils/cashflows-filter-helper';
import { cashflowsSelector } from 'store/cashflows/selector';
import { useCashflowsFilters } from 'hooks/cashflows';
import Chip from 'components/atoms/Chip/Chip.styles';
import { formatArrayToString } from 'utils/format-array-to-string';

interface CashflowsTableProps {
  onResyncClick: (id: number) => void;
  onEditClick: (id: number) => void;
  onCopyClick: (id: number) => void;
}

const CashflowsTable: FC<CashflowsTableProps> = ({ onResyncClick, onEditClick, onCopyClick }) => {
  const [searchParams] = useSearchParams();

  const defaultFilters = getCashflowsFilters(searchParams);
  const defaultSortModel = getCashflowsSortModel(searchParams);

  const cashflows = useSelector(cashflowsSelector);

  const {
    filters,
    pagination,
    sortModel,
    handleFilterSubmit,
    handleFiltersReset,
    handlePaginationModelChange,
    handleSortingModelChange,
  } = useCashflowsFilters(defaultSortModel);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'casino_name',
        headerName: 'Casino name',
        minWidth: 170,
      },
      {
        field: 'name',
        headerName: 'Cashflow name',
        minWidth: 170,
      },
      {
        field: 'currency',
        headerName: 'Currency',
        minWidth: 105,
      },
      {
        field: 'acceptable_currencies',
        headerName: 'Acceptable currencies',
        minWidth: 230,
        sortable: false,
        flex: 1,
        renderCell: ({ value: acceptable_currencies }: GridRenderCellParams<CashflowItem, string[]>) => {
          return acceptable_currencies?.length ? (
            <Box display="flex" flexWrap="wrap" gap={0.5}>
              {acceptable_currencies.map((currency) => (
                <Chip key={currency} size="small" label={currency} variant="outlined" />
              ))}
            </Box>
          ) : null;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        align: 'center',
        minWidth: 120,
        cellClassName: 'ps-status-cell',
        renderCell: ({ row: { error, status } }: GridRenderCellParams<SyncDetails>) =>
          status && <SyncStatusInfo status={status} error={error} />,
      },
      {
        field: 'configure_childs',
        headerName: 'Configured childs',
        minWidth: 230,
        flex: 1,
        sortable: false,
        valueFormatter: (value: string[]) => formatArrayToString(value),
      },
      {
        field: 'actions',
        headerName: 'Action',
        minWidth: 145,
        sortable: false,
        renderCell: ({ row: { id, status } }: GridRenderCellParams<CashflowItem>) => (
          <Box display="flex" flexWrap="nowrap" justifyContent="center" gap="4px">
            <ActionButton onClick={() => onCopyClick(id)}>Copy</ActionButton>
            <ActionButton onClick={() => onEditClick(id)}>Edit</ActionButton>
            <ActionButton disabled={!isEntityNonSynced(status)} onClick={() => onResyncClick(id)}>
              {getSyncStatusButtonText(status)}
            </ActionButton>
          </Box>
        ),
      },
    ],
    [onResyncClick, onEditClick, onCopyClick],
  );

  return (
    <>
      <FiltersBar
        filters={filters}
        defaultFilters={defaultFilters}
        onSubmit={handleFilterSubmit}
        onResetFilters={handleFiltersReset}
      />
      <DataGrid
        paginationMode="server"
        sortingMode="server"
        rowCount={pagination.total_count}
        paginationModel={{
          pageSize: pagination.per_page,
          page: pagination.current_page - 1,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        onSortModelChange={handleSortingModelChange}
        sortModel={sortModel}
        columns={columns}
        rows={cashflows}
        autoRowHeight
      />
    </>
  );
};

export default CashflowsTable;
