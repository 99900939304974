import { combineEpics, type Epic } from 'redux-observable';
import { mergeMapTo, map, filter } from 'rxjs/operators';
import { loadPaymentProviders } from 'api/payment-providers';
import { loadTagsAction } from 'api/tags';
import { loadCurrenciesAction } from 'api/currencies';
import { loadCasinosAction } from 'api/casinos';
import { forceLoadAccessDataAction, resetAccessDataAction } from 'api/access-data';
import { loadLimitsAction } from 'api/limits';
import {
  loadPaymentSystemFulfilledAction,
  resetCreatePaymentSystemAction,
  resetPaymentSystemAction,
  resetUpdatePaymentSystemAction,
} from 'api/payment-systems';
import type { RootAction } from 'types/sotre/actions/root';
import type { RootState } from 'types/sotre/state/root';
import { isActionOf } from 'typesafe-actions';
import { loadCryptoPairs } from 'api/crypto-pairs';

import { initPaymentSystemFormAction, resetPaymentSystemFormAction } from './actions';

const initPaymentSystemFormEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(initPaymentSystemFormAction)),
    mergeMapTo([
      loadPaymentProviders(),
      loadTagsAction(),
      loadCurrenciesAction(),
      loadCasinosAction(),
      loadLimitsAction(),
      loadCryptoPairs(),
    ]),
  );

const resetPaymentSystemFormEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(resetPaymentSystemFormAction)),
    mergeMapTo([
      resetUpdatePaymentSystemAction(),
      resetCreatePaymentSystemAction(),
      resetPaymentSystemAction(),
      resetAccessDataAction(),
    ]),
  );

const loadPaymentSystemSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(loadPaymentSystemFulfilledAction)),
    map(({ payload: { response } }) => forceLoadAccessDataAction(response.payment_provider.name)),
  );

export default combineEpics(initPaymentSystemFormEpic, resetPaymentSystemFormEpic, loadPaymentSystemSuccessEpic);
