import { requestsFactory } from 'redux-requests-factory';
import type { Origin } from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getOrigins = (): Promise<Origin[]> => axiosInstance.get(ENDPOINTS.ORIGINS);

export const { loadDataAction: loadOriginsAction, responseSelector: originsResponseSelector } = requestsFactory({
  request: getOrigins,
  stateRequestKey: 'origins',
  transformResponse: (response?: Origin[]) => response ?? [],
});
