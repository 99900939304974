import { cashflowsFilterKeys, CashflowsSearchParam, type CashflowsFilterKey } from 'constants/cashflows-search-params';

import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { cashflowsFiltersSelector, cashflowsPaginationSelector } from 'store/cashflows/selector';

export const useCashflowsFilters = (defaultSortModel: GridSortModel) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const pagination = useSelector(cashflowsPaginationSelector);
  const filters = useSelector(cashflowsFiltersSelector);

  const handleFilterSubmit = useCallback(
    (filters: Record<string, string[]>) => {
      setSearchParams(
        (prev) => {
          Object.keys(cashflowsFilterKeys).forEach((key) => {
            const filterKey = key as CashflowsFilterKey;
            if (filters?.[filterKey]?.length) {
              prev.set(cashflowsFilterKeys[filterKey], filters[key].join(','));
            } else {
              prev.delete(cashflowsFilterKeys[filterKey]);
            }

            prev.delete(CashflowsSearchParam.Page);
          });

          return prev;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const handleFiltersReset = useCallback(() => {
    setSortModel([]);
    setSearchParams(
      (prev) => {
        Object.values(CashflowsSearchParam).forEach((key) => {
          prev.delete(key);
        });

        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  const handlePaginationModelChange = useCallback(
    (paginationModel: GridPaginationModel) => {
      setSearchParams(
        (prev) => {
          prev.set(CashflowsSearchParam.Page, (paginationModel.page + 1).toString());
          return prev;
        },
        { replace: true },
      );

      window.scrollTo({ top: 0 });
    },
    [setSearchParams],
  );

  const handleSortingModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
      setSearchParams(
        (prev) => {
          const sortItem = newSortModel.length >= 0 ? newSortModel[0] : null;
          if (sortItem) {
            prev.set(CashflowsSearchParam.SortField, sortItem.field);
            prev.set(CashflowsSearchParam.SortDirection, sortItem.sort ?? '');
          } else {
            prev.delete(CashflowsSearchParam.SortField);
            prev.delete(CashflowsSearchParam.SortDirection);
          }

          return prev;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  return {
    searchParams,
    filters,
    pagination,
    sortModel,
    handleFilterSubmit,
    handleFiltersReset,
    handlePaginationModelChange,
    handleSortingModelChange,
  };
};
