import { COMMISSIONS_COLUMNS, COMMISSIONS_ROWS, PERCENT_COMMISSIONS } from 'constants/cashflow';
import { MAX_SCALE, PERCENT_SCALE } from 'constants/scales';

import { Box, Typography } from '@mui/material';
import { TableHeader, TableHeaderItem, TableInputWrapper, TableRow, TableRowLabel } from 'components/molecules/Table';
import React from 'react';
import type { FC } from 'react';
import { Controller, type Control, type FieldErrors } from 'react-hook-form';
import type { Cashflow, ChildCommissions, Commissions, CommmissionsRow } from 'types/entities';
import NumberInput from 'components/atoms/NumberInput';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';
import type { Currency } from 'types/api';
import FormSelect from 'components/atoms/FormSelect/FormSelect';

interface CommissionsTableProps {
  control: Control<Cashflow>;
  index: number;
  commissions: ChildCommissions;
  currenciesOptions: FormSelectOption[];
  currencies: Currency[];
  errors: FieldErrors<Cashflow>;
}

const gridTemplateColumns = `100px repeat(${COMMISSIONS_COLUMNS.length}, minmax(0, 1fr))`;

export const CommissionsTable: FC<CommissionsTableProps> = ({
  control,
  index,
  commissions,
  currenciesOptions,
  currencies,
  errors,
}) => {
  const commissionsErrors = errors.childs?.[index]?.commissions;

  const renderCommission = (type: keyof ChildCommissions, commissionName: keyof Commissions) => {
    const commission = commissions[type][commissionName];

    if (!commission) {
      return (
        <TableInputWrapper key={`childs.${type}.${commissionName}`} textAlign="center">
          <Typography variant="body2">Not applicable</Typography>
        </TableInputWrapper>
      );
    }

    const commissionErrors = commissionsErrors?.[type]?.[commissionName];
    const currency = currencies.find((c) => c.iso_code === commission.currency);
    const showCurrency = commission.currency !== undefined;
    const scale = PERCENT_COMMISSIONS.includes(commissionName)
      ? PERCENT_SCALE
      : currency?.digits_after_comma ?? MAX_SCALE;

    return (
      <TableInputWrapper key={`childs.${type}.${commissionName}`} display="flex" gap={1}>
        <Box flexGrow="1" maxWidth={showCurrency ? '60%' : '100%'}>
          <Controller
            name={`childs.${index}.commissions.${type}.${commissionName}.value`}
            control={control}
            defaultValue={commission.value}
            render={({ field }) => (
              <NumberInput {...field} scale={scale} error={commissionErrors?.value} showValidationTooltip={true} />
            )}
          />
        </Box>
        {showCurrency && (
          <Box flexGrow="1" maxWidth="38%">
            <Controller
              name={`childs.${index}.commissions.${type}.${commissionName}.currency`}
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  options={currenciesOptions}
                  error={commissionErrors?.currency}
                  showValidationTooltip
                  showBlankValue
                  blankValue=""
                />
              )}
            />
          </Box>
        )}
      </TableInputWrapper>
    );
  };

  const renderRow = ({ key: type, label }: CommmissionsRow) => (
    <TableRow key={type} display="flex" p="12px 0" flexDirection="column">
      <Box alignItems="center" display="grid" gridTemplateColumns={gridTemplateColumns}>
        <Box p="0 12px" display="flex" alignItems="center" justifyContent="space-between">
          <TableRowLabel variant="caption" fontWeight="700">
            {label}
          </TableRowLabel>
        </Box>

        {COMMISSIONS_COLUMNS.map(({ key }) => renderCommission(type, key))}
      </Box>
    </TableRow>
  );

  return (
    <Box width="calc(100% + 16px)" display="flex" flexDirection="column" marginLeft={-2}>
      <TableHeader display="grid" gridTemplateColumns={gridTemplateColumns}>
        <TableHeaderItem>Type</TableHeaderItem>
        {COMMISSIONS_COLUMNS.map(({ key, label }) => (
          <TableHeaderItem key={key}>{label}</TableHeaderItem>
        ))}
      </TableHeader>
      {COMMISSIONS_ROWS.map(renderRow)}
    </Box>
  );
};
