import { Grid } from '@mui/material';
import FormDivider from 'components/atoms/FormDivider';
import FormTextField from 'components/atoms/FormTextField';
import React from 'react';
import type { FC } from 'react';
import type { Control, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { Cashflow } from 'types/entities';

interface CashflowNameSectionProps {
  control: Control<Cashflow>;
  errors: FieldErrors<Cashflow>;
}

export const CashflowNameSection: FC<CashflowNameSectionProps> = ({ control, errors }) => (
  <>
    <Grid item xs={5}>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field }) => <FormTextField {...field} label="Cashflow name" error={errors.name} required />}
      />
    </Grid>

    <Grid item xs={12}>
      <FormDivider />
    </Grid>
  </>
);
