import type { Casino } from 'types/api';
import type { Licenses } from 'types/api/licenses';

export const getLicensesByCasino = (licenses: Licenses, casinoName: string) => {
  const casinoLicenses = licenses[casinoName] ?? [];

  return casinoLicenses;
};

export const geCasinoLicensesOptions = (
  licenses: Licenses,
  casinos: Casino[],
  casinoId: number,
  isEditMode: boolean,
) => {
  if (!isEditMode || casinos.length === 0) {
    return [];
  }

  const casinoName = casinos.find((casino) => casino.id === casinoId)?.codename ?? '';
  const casinoLicenses = getLicensesByCasino(licenses, casinoName);
  const options = casinoLicenses.map((value) => ({ label: value, value }));

  return options;
};
