import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DataGrid from 'components/molecules/DataGrid';
import FiltersBar from 'components/molecules/FiltersBar';
import { paymentSystemsSelector } from 'store/payment-system/selectors';
import { getSyncStatusButtonText, isEntityNonSynced } from 'utils/sync-status-helper';
import ActionButton from 'components/atoms/ActionButton';
import type { SyncDetails } from 'types/api';
import SyncStatusInfo from 'components/molecules/SyncStatusInfo';
import { usePaymentSystemFilters } from 'hooks/payment-systems';
import { getPaymentSystemsFilters, getPaymentSystemsSortModel } from 'utils/payment-systems-filter-helper';
import { useSearchParams } from 'react-router-dom';

import PaymentSystemTags from './subcomponents/PaymentSystemTags';

interface PaymentSystemsTableProps {
  onResyncClick: (id: number) => void;
  onEditClick: (id: number) => void;
}

const PaymentSystemsTable: FC<PaymentSystemsTableProps> = ({ onResyncClick, onEditClick }) => {
  const [searchParams] = useSearchParams();

  const defaultFilters = getPaymentSystemsFilters(searchParams);
  const defaultSortModel = getPaymentSystemsSortModel(searchParams);

  const paymentSystems = useSelector(paymentSystemsSelector);

  const {
    filters,
    pagination,
    sortModel,
    handleFilterSubmit,
    handleFiltersReset,
    handlePaginationModelChange,
    handleSortingModelChange,
  } = usePaymentSystemFilters(defaultSortModel);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'casinoName',
        headerName: 'Casino name',
        minWidth: 230,
      },
      {
        field: 'childSystem',
        headerName: 'Child system',
        minWidth: 180,
      },
      {
        field: 'systemName',
        headerName: 'System name',
        minWidth: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        align: 'center',
        minWidth: 120,
        cellClassName: 'ps-status-cell',
        renderCell: ({ row: { error, status } }: GridRenderCellParams<SyncDetails>) =>
          status && <SyncStatusInfo status={status} error={error} />,
      },
      {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: ({ value: tags }: GridRenderCellParams<{ tags: string[] }, string[]>) => {
          if (tags?.length) {
            return <PaymentSystemTags tags={tags} />;
          }
        },
      },
      {
        field: 'actions',
        headerName: 'Action',
        minWidth: 105,
        sortable: false,
        renderCell: ({ row: { id, status } }: GridRenderCellParams<SyncDetails>) => (
          <Box display="flex" flexWrap="nowrap">
            <Box mr="4px">
              <ActionButton onClick={() => onEditClick(id)}>Edit</ActionButton>
            </Box>
            <Box>
              <ActionButton disabled={!isEntityNonSynced(status)} onClick={() => onResyncClick(id)}>
                {getSyncStatusButtonText(status)}
              </ActionButton>
            </Box>
          </Box>
        ),
      },
    ],
    [onResyncClick, onEditClick],
  );

  return (
    <>
      <FiltersBar
        filters={filters}
        defaultFilters={defaultFilters}
        onSubmit={handleFilterSubmit}
        onResetFilters={handleFiltersReset}
      />
      <DataGrid
        paginationMode="server"
        sortingMode="server"
        rowCount={pagination.total_count}
        paginationModel={{
          pageSize: pagination.per_page,
          page: pagination.current_page - 1,
        }}
        onPaginationModelChange={handlePaginationModelChange}
        onSortModelChange={handleSortingModelChange}
        sortModel={sortModel}
        columns={columns}
        rows={paymentSystems}
      />
    </>
  );
};

export default PaymentSystemsTable;
