import { requestsFactory } from 'redux-requests-factory';
import type { CryptoPairs } from 'types/api/crypto-pairs';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getCryptoPairs = (): Promise<CryptoPairs> => axiosInstance.get(ENDPOINTS.CRYPTO_PAIRS);

export const { loadDataAction: loadCryptoPairs, responseSelector: cryptoPairsResponseSelector } = requestsFactory({
  request: getCryptoPairs,
  stateRequestKey: 'crypto-pairs',
  transformResponse: (response?: CryptoPairs) => response ?? { crypto_pairs_deposit: [], crypto_pairs_cashout: [] },
});
