import { combineEpics, type Epic } from 'redux-observable';
import { mergeMapTo, filter } from 'rxjs/operators';
import { loadPaymentProviders } from 'api/payment-providers';
import { forcedLoadTagsAction } from 'api/tags';
import { loadCasinosAction } from 'api/casinos';
import type { RootAction } from 'types/sotre/actions/root';
import type { RootState } from 'types/sotre/state/root';
import { isActionOf } from 'typesafe-actions';

import { initBulkPaymentSystemFormAction } from './actions';

const initBulkPaymentSystemFormEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(initBulkPaymentSystemFormAction)),
    mergeMapTo([loadPaymentProviders(), forcedLoadTagsAction(), loadCasinosAction()]),
  );

export default combineEpics(initBulkPaymentSystemFormEpic);
