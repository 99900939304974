import { HEADER_HEIGHT } from 'constants/app';

import styled from 'styled-components';
import Container from '@mui/material/Container';

export const AppWrapper = styled(Container)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const PageWrapper = styled(Container)`
  padding: ${({ theme }) => theme.spacing(1, 0)};
  margin-top: ${HEADER_HEIGHT}px;
  flex: 1;
  overflow-y: hidden;
`;
