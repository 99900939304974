import { Box, styled, Typography, type Theme } from '@mui/material';

export const TableHeader = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.background.panel,
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

export const TableHeaderItemContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  padding: '10px 8px',
  borderLeft: `1px solid ${theme.palette.background.default}`,

  '&:nth-last-child(1)': {
    borderRight: `1px solid ${theme.palette.background.default}`,
  },
}));

export const TableRowLabel = styled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.text.dark,
}));

export const TableInputWrapper = styled(Box)({
  padding: '0 8px',
});

export const TableRow = styled(Box)(({ theme }: { theme: Theme }) => ({
  borderBottom: `1px solid ${theme.palette.background.style2}`,

  '&:nth-child(even)': {
    backgroundColor: theme.palette.common.white,
  },

  '&:nth-child(odd)': {
    backgroundColor: theme.palette.background.form,
  },
}));
