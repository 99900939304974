import { PaymentSystemEditAction, type Casino, type PaymentSystem } from 'types/api';
import type { PaymentSystemBulkEditModel } from 'types/entities';

export const DEFAULT_RESTRICTIONS = '{}';

export const DEFAULT_CASINO: Casino = {
  codename: 'default',
  id: 0,
  entity_name: '',
};

export const INITIAL_PAYMENT_SYSTEM: PaymentSystem = {
  id: 0,
  providerName: '',
  systemName: '',
  testMode: false,
  restrictions: DEFAULT_RESTRICTIONS,
  tags: {
    applied: [],
    excluded: [],
  },
  currencies: [],
  limits: {},
  protocols: {},
  casinos: [],
  hideSensitiveData: true,
  includePspService: true,
  casino_settings: {
    [DEFAULT_CASINO.id]: {
      casino_id: DEFAULT_CASINO.id,
      payment_system_fee: 0,
      payment_system_fee_currency: '',
      payment_system_fee_percent: 0,
      access_data: {},
    },
  },
  paymentGroups: '',
  excludedPaymentGroups: '',
  cryptoPairsCashout: [],
  cryptoPairsDeposit: [],
};

export const INITIAL_BULK_PAYMENT_SYSTEMS: PaymentSystemBulkEditModel = {
  payment_system_name: '',
  child_system_name: '',
  casino_ids: [],
  tags: [],
  excluded_tags: [],
  payment_groups: '',
  excluded_payment_groups: '',
  action: PaymentSystemEditAction.Add,
};

export const PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY = ['finteqhub_seamless'];
export const PROVIDERS_WITH_DEFAULT_ACCESS_DATA = ['accentpay'];
export const COINSPAID_PROVIDER = 'coinspaid';
