import styled from 'styled-components';
import { ToggleButton as MuiToggleButton } from '@mui/material';

export const ToggleButton = styled(MuiToggleButton)`
  height: 28px;
  font-size: 12px;

  &.Mui-selected,
  &.Mui-selected:hover {
    color: ${({ theme }) => theme.palette.text.white};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
