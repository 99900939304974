import type { CasinoOption } from 'types/api';
import { getStatusText } from 'utils/sync-status-helper';

export const mapValuesToOptions = (values: string[]) => {
  return values.map((value) => ({
    label: value,
    value,
  }));
};

export const mapStatusesToOptions = (statuses: string[]) => {
  return statuses.map((value) => ({
    label: getStatusText(value),
    value,
  }));
};

export const mapCasinosToOptions = (casinos: CasinoOption[]) => {
  return casinos.map((casino) => ({
    label: casino.name,
    value: casino.id.toString(),
  }));
};
