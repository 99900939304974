import type { SyncStatus } from 'constants/sync-status';

import type { FC } from 'react';
import React from 'react';
import Badge from 'components/atoms/Badge';
import { getStatusBadgeVariant, getStatusText } from 'utils/sync-status-helper';

interface StatusBadgeProps {
  status: SyncStatus;
}

const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
  const variant = getStatusBadgeVariant(status);
  const statusText = getStatusText(status);

  return <Badge variant={variant}>{statusText}</Badge>;
};

export default StatusBadge;
