import { PROVIDERS_WITH_FEE_PERCENT, PROVIDERS_WITH_FEES, PROVIDERS_WITH_PSP_SERVICE } from 'constants/access-data';

import { Grid, type SxProps, type Theme } from '@mui/material';
import FormDivider from 'components/atoms/FormDivider';
import React from 'react';
import type { FC } from 'react';
import type { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import {
  type AccessDataField as AccessDataFieldModel,
  type Casino,
  type Currency,
  type PaymentSystem,
} from 'types/api';
import { Tabs, type TabItem } from 'components/molecules/Tabs';
import FormCheckbox from 'components/atoms/FormCheckbox';
import { FormSectionTitle } from 'components/molecules/FormContainer';
import FormButton from 'components/atoms/FormButton';

import AccessDataFormField from './AccessDataFormField';
import FeeFields from './FeeFields';

interface AccessDataSectionProps {
  control: Control<PaymentSystem>;
  fields: AccessDataFieldModel[];
  casinos: Casino[];
  currencies: Currency[];
  paymentProvider: string;
  hideSensitiveData: boolean;
  errors: FieldErrors<PaymentSystem>;
  disabeleImport: boolean;
  onInmport: VoidFunction;
  onInmportFees: VoidFunction;
  watch: UseFormWatch<PaymentSystem>;
  onValidateAccessData: (casinoId: number, fieldName: string) => void;
  onValidateFees: (casinoId: number, fieldName: string) => void;
}

const importFessButtonStyles: SxProps<Theme> = { mr: '16px' };
const importAccessDataButtonStyles: SxProps<Theme> = { mr: '24px' };

export const AccessDataSection: FC<AccessDataSectionProps> = ({
  control,
  casinos,
  currencies,
  paymentProvider,
  fields,
  hideSensitiveData,
  errors,
  disabeleImport,
  onInmport,
  onInmportFees,
  watch,
  onValidateAccessData,
  onValidateFees,
}) => {
  let tabs: TabItem[] = [];
  const showPaymentFees = PROVIDERS_WITH_FEES.includes(paymentProvider);
  const showPaymentFeePercent = PROVIDERS_WITH_FEE_PERCENT.includes(paymentProvider);
  const showImportFees = showPaymentFees || showPaymentFeePercent;
  const showIncludePspService = PROVIDERS_WITH_PSP_SERVICE.includes(paymentProvider);

  if (casinos.length > 0) {
    tabs = casinos.map((casino) => {
      return {
        label: casino.codename,
        invalid: !!errors.casino_settings?.[casino.id],
        component: (
          <Grid container rowGap={2} columnGap={2} m={0}>
            <Grid item xs={10} display="flex" flexWrap="wrap" columnGap={2}>
              <FeeFields
                casinoId={casino.id}
                control={control}
                currencies={currencies}
                errors={errors}
                showPaymentFeePercent={showPaymentFeePercent}
                showPaymentFees={showPaymentFees}
                watch={watch}
                onValidateFees={onValidateFees}
              />
              {fields.map((field) => (
                <AccessDataFormField
                  key={`access-data-${casino.id}-${field.name}`}
                  accessDataField={field}
                  control={control}
                  casinoId={casino.id}
                  hideSensitiveData={hideSensitiveData}
                  errors={errors}
                  watch={watch}
                  onValidateAccessData={onValidateAccessData}
                />
              ))}
            </Grid>
          </Grid>
        ),
      };
    });
  }

  return (
    <>
      <Grid item xs={6}>
        <FormSectionTitle>Access data & fee</FormSectionTitle>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        {showImportFees && (
          <FormButton type="button" sx={importFessButtonStyles} disabled={disabeleImport} onClick={onInmportFees}>
            Import system fee
          </FormButton>
        )}
        <FormButton type="button" sx={importAccessDataButtonStyles} disabled={disabeleImport} onClick={onInmport}>
          Import access data
        </FormButton>
      </Grid>

      <Grid item xs={6}>
        <Controller
          name="hideSensitiveData"
          control={control}
          render={({ field }) => <FormCheckbox {...field} label="Hide sensitive data" />}
        />
      </Grid>

      {showIncludePspService && (
        <Grid item xs={6} display="flex" justifyContent="flex-end" pr={1}>
          <Controller
            name="includePspService"
            control={control}
            render={({ field }) => <FormCheckbox {...field} label="Include PSP service" />}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {tabs.length > 0 && <Tabs tabs={tabs} />}
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
