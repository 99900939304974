import {
  PaymentSystemSearchParam,
  paymentSystemFilterKeys,
  type PaymentSystemFilterKey,
} from 'constants/payment-system-search-param';

import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  filterCasinosSelector,
  filterChildSystemsSelector,
  filterCurrenciesSelector,
  filterPaymentProvidersSelector,
  filterStatusesSelector,
  filterTagsSelector,
} from 'store/payment-systems/selectors';
import { useSearchParams } from 'react-router-dom';
import { paymentSystemsPaginationSelector } from 'store/payment-system/selectors';

export const usePaymentSystemFilters = (defaultSortModel: GridSortModel) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const pagination = useSelector(paymentSystemsPaginationSelector);

  const filterPaymentProviders = useSelector(filterPaymentProvidersSelector);
  const filterChildSystems = useSelector(filterChildSystemsSelector);
  const filterTags = useSelector(filterTagsSelector);
  const filterCurrencies = useSelector(filterCurrenciesSelector);
  const filterStatuses = useSelector(filterStatusesSelector);
  const filterCasinos = useSelector(filterCasinosSelector);

  const filters = useMemo(
    () => ({
      casinos: {
        id: 'casinos',
        label: 'Casino names',
        options: filterCasinos,
      },
      childSystem: {
        id: 'child-system',
        label: 'Child systems',
        options: filterChildSystems,
      },
      systemName: {
        id: 'system-name',
        label: 'System names',
        options: filterPaymentProviders,
      },
      tags: {
        id: 'tags',
        label: 'Tags',
        options: filterTags,
      },
      currencies: {
        id: 'currencies',
        label: 'Currencies',
        options: filterCurrencies,
      },
      status: {
        id: 'status',
        label: 'Statuses',
        options: filterStatuses,
      },
    }),
    [filterCasinos, filterChildSystems, filterPaymentProviders, filterTags, filterCurrencies, filterStatuses],
  );

  const handleFilterSubmit = useCallback(
    (filters: Record<string, string[]>) => {
      setSearchParams(
        (prev) => {
          Object.keys(paymentSystemFilterKeys).forEach((key) => {
            const filterKey = key as PaymentSystemFilterKey;
            if (filters?.[filterKey]?.length) {
              prev.set(paymentSystemFilterKeys[filterKey], filters[key].join(','));
            } else {
              prev.delete(paymentSystemFilterKeys[filterKey]);
            }

            prev.delete(PaymentSystemSearchParam.Page);
          });

          return prev;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const handleFiltersReset = useCallback(() => {
    setSortModel([]);
    setSearchParams(
      (prev) => {
        Object.values(PaymentSystemSearchParam).forEach((key) => {
          prev.delete(key);
        });

        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  const handlePaginationModelChange = useCallback(
    (paginationModel: GridPaginationModel) => {
      setSearchParams(
        (prev) => {
          prev.set(PaymentSystemSearchParam.Page, (paginationModel.page + 1).toString());
          return prev;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const handleSortingModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
      setSearchParams(
        (prev) => {
          const sortItem = newSortModel.length > 0 ? newSortModel[0] : null;
          if (sortItem) {
            prev.set(PaymentSystemSearchParam.SortField, sortItem.field);
            prev.set(PaymentSystemSearchParam.SortDirection, sortItem.sort ?? '');
          } else {
            prev.delete(PaymentSystemSearchParam.SortField);
            prev.delete(PaymentSystemSearchParam.SortDirection);
          }

          return prev;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  return {
    searchParams,
    filters,
    pagination,
    sortModel,
    handleFilterSubmit,
    handleFiltersReset,
    handlePaginationModelChange,
    handleSortingModelChange,
  };
};
