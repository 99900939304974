import { createSelector } from 'reselect';
import { cashflowsResponseSelector } from 'api/cashflows';
import {
  isCreateCompletedSelector,
  isUpdateCompletedSelector,
  createPaymentSystemErrorSelector,
  updatePaymentSystemErrorSelector,
} from 'api/payment-systems';

export const isSaveCompletedSelector = createSelector(
  [isUpdateCompletedSelector, isCreateCompletedSelector],
  (isUpdateCompleted, isCreateCompleted) => isUpdateCompleted || isCreateCompleted,
);

export const paymentSystemErrorSelector = createSelector(
  [createPaymentSystemErrorSelector, updatePaymentSystemErrorSelector],
  (createError, updateError) => createError || updateError,
);

export const cashflowsOptionsSelector = createSelector(cashflowsResponseSelector, (cashflowsResponse) =>
  cashflowsResponse.cashflows.map(({ id, name }: { id: number; name: string }) => ({ label: name, value: id })),
);
