import { DEFAULT_CASINO } from 'constants/payment-system';

import React, { useEffect, type FC } from 'react';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import type { Control, ControllerRenderProps, FieldError, FieldErrors, UseFormWatch } from 'react-hook-form';
import { FieldType, type AccessDataField, type PaymentSystem } from 'types/api';
import FormSelect from 'components/atoms/FormSelect';
import FormTextField from 'components/atoms/FormTextField';
import FormCheckbox from 'components/atoms/FormCheckbox';
import NumberInput from 'components/atoms/NumberInput';
import { MultiSelect } from 'components/atoms/MultiSelect';

interface AccessDataFormFieldProps {
  accessDataField: AccessDataField;
  control: Control<PaymentSystem>;
  casinoId: number;
  hideSensitiveData: boolean;
  errors: FieldErrors<PaymentSystem>;
  watch: UseFormWatch<PaymentSystem>;
  onValidateAccessData: (casinoId: number, fieldName: string) => void;
}

const renderField = (
  accessDataField: AccessDataField,
  field: ControllerRenderProps<PaymentSystem>,
  hideSensitiveData: boolean,
  required: boolean,
  error?: FieldError,
) => {
  switch (accessDataField.type) {
    case FieldType.Integer:
      return (
        <NumberInput
          {...field}
          label={accessDataField.label}
          mask={/^[1-9]\d*$/}
          type={hideSensitiveData ? 'password' : 'text'}
          required={required}
          error={error}
        />
      );
    case FieldType.String: {
      if (accessDataField.enum) {
        const options = accessDataField.enum.map((value) => ({ value, label: value }));
        return (
          <FormSelect {...field} label={accessDataField.label} options={options} required={required} error={error} />
        );
      } else {
        return (
          <FormTextField
            {...field}
            label={accessDataField.label}
            type={hideSensitiveData && !accessDataField.isRsaKey ? 'password' : 'text'}
            required={required}
            multiline={!hideSensitiveData || accessDataField.isRsaKey}
            error={error}
          />
        );
      }
    }
    case FieldType.Boolean:
      return <FormCheckbox {...field} label={accessDataField.label} />;
    case FieldType.Array:
      const items = accessDataField.items?.enum ?? [];
      const options = items.map((value) => ({ value, label: value, disabled: false }));
      field.value = Array.isArray(field.value) ? field.value : [];
      return (
        <MultiSelect
          {...field}
          value={field.value as (string | number)[]}
          label={accessDataField.label}
          options={options}
          required={required}
          error={error}
        />
      );
    default:
      return <></>;
  }
};

const AccessDataFormField: FC<AccessDataFormFieldProps> = ({
  accessDataField,
  control,
  casinoId,
  hideSensitiveData,
  errors,
  watch,
  onValidateAccessData,
}) => {
  const error = errors.casino_settings?.[casinoId]?.access_data?.[accessDataField.name] as FieldError | undefined;
  const dependentFieldValue =
    accessDataField.requiredIf.length > 0
      ? watch(`casino_settings.${casinoId}.access_data.${accessDataField.requiredIf[0]}`)
      : null;

  useEffect(() => {
    onValidateAccessData(casinoId, accessDataField.name);
  }, [dependentFieldValue, casinoId, accessDataField, onValidateAccessData]);

  const required =
    (accessDataField.required || (accessDataField.requiredIf.length > 0 && !!dependentFieldValue)) &&
    casinoId !== DEFAULT_CASINO.id;

  return (
    <Grid item width="calc(50% - 8px)" pt={3} key={`access-data-${casinoId}-${accessDataField.name}`}>
      <Controller
        name={`casino_settings.${casinoId}.access_data.${accessDataField.name}`}
        control={control}
        render={({ field }) => renderField(accessDataField, field, hideSensitiveData, required, error)}
      />
    </Grid>
  );
};

export default AccessDataFormField;
