export enum PaymentSystemEditAction {
  Add = 'add',
  Remove = 'remove',
}

export interface PaymentSystemBulkEditRequest {
  payment_system_name: string;
  child_system_name: string;
  casino_ids: number[];
  tags: string[];
  excluded_tags: string[];
  payment_groups: string[];
  excluded_payment_groups: string[];
  operation: PaymentSystemEditAction;
}

export interface BulkUpdateErrors {
  excluded_payment_groups: string;
  excluded_tags: string;
  payment_groups: string;
  tags: string;
}

export interface BulkUpdateError {
  errors: BulkUpdateErrors | string;
}
