import { combineReducers } from 'redux';
import { requestsReducer, stateRequestsKey } from 'redux-requests-factory';

import { snapshotFormReducer } from './snapshot-form';
import { casinoSnapshotsFormReducer } from './casino-snapshots-form';

export default combineReducers({
  [stateRequestsKey]: requestsReducer,
  snapshotForm: snapshotFormReducer,
  casinoSnapshotsForm: casinoSnapshotsFormReducer,
});
