import type { CashflowsFiltersResponse } from 'types/api';

export enum CashflowsSearchParam {
  Page = 'page',
  Sorting = 's',
  SortField = 'sort_field',
  SortDirection = 'sort_dir',
  CashflowNames = 'q[cashflow_names_in]',
  Currencies = 'q[currencies_in]',
  AcceptableCurrencies = 'q[acceptable_currencies_in]',
  ConfiguredChilds = 'q[configured_childs_in]',
  Origins = 'q[origins_in]',
  LicenseNames = 'q[licenses_in]',
  Statuses = 'q[statuses_in]',
  Casinos = 'q[casino_id_in]',
}

export const cashflowsFilterKeys = {
  cashflowNames: CashflowsSearchParam.CashflowNames,
  currencies: CashflowsSearchParam.Currencies,
  acceptableCurrencies: CashflowsSearchParam.AcceptableCurrencies,
  statuses: CashflowsSearchParam.Statuses,
  configuredChilds: CashflowsSearchParam.ConfiguredChilds,
  origins: CashflowsSearchParam.Origins,
  licenseNames: CashflowsSearchParam.LicenseNames,
  casinos: CashflowsSearchParam.Casinos,
};

export type CashflowsFilterKey = keyof typeof cashflowsFilterKeys;

export const DEFAULT_CASHFLOWS_FILTERS: CashflowsFiltersResponse = {
  cashflow_names: [],
  currencies: [],
  acceptable_currencies: [],
  statuses: [],
  configured_childs: [],
  origins: [],
  licenses: [],
  casinos: [],
};

const propertyToSortFieldMap: Record<string, string> = {
  casino_name: 'casino_name',
  name: 'cashflow_name',
  currency: 'currency',
  payment_system_fee: 'payment_system_fee',
  status: 'status',
};

export const getSortFieldByPropertyName = (key: string): string => {
  return propertyToSortFieldMap[key];
};
