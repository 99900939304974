import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getTrustedLevels = (): Promise<string[]> => axiosInstance.get(ENDPOINTS.TRUSTED_LEVELS);

export const { loadDataAction: loadTrustedLevelsAction, responseSelector: trustedLevelsResponseSelector } =
  requestsFactory({
    request: getTrustedLevels,
    stateRequestKey: 'trusted-levels',
    transformResponse: (response?: string[]) => response ?? [],
  });
