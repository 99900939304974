import { requestsFactory } from 'redux-requests-factory';
import type { CasinoOption, CasinosResponse } from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getCasinos = (): Promise<CasinosResponse> => axiosInstance.get(ENDPOINTS.CASINOS);

export const { loadDataAction: loadCasinosAction, responseSelector: casinosResponseSelector } = requestsFactory({
  request: getCasinos,
  stateRequestKey: 'casinos',
  transformResponse: (response?: CasinosResponse) => response?.casinos ?? [],
});

const getFilterCasinos = (): Promise<CasinoOption[]> => axiosInstance.get(ENDPOINTS.FILTER_CASINOS);

export const { loadDataAction: loadFilterCasinos, responseSelector: filterCasinosResponseSelector } = requestsFactory({
  request: getFilterCasinos,
  stateRequestKey: 'filter-casinos',
  transformResponse: (response?: CasinoOption[]) => response ?? [],
  includeInGlobalLoading: false,
});
