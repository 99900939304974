import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Grid } from '@mui/material';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { PaymentSystem } from 'types/api';
import FormDivider from 'components/atoms/FormDivider';
import { MultiSelect } from 'components/atoms/MultiSelect';
import { FormSectionTitle } from 'components/molecules/FormContainer';

interface CurrencyPairsSectionProps {
  control: Control<PaymentSystem>;
  cryptoPairsDeposit: string[];
  cryptoPairsCashout: string[];
}

export const CurrencyPairsSection: FC<CurrencyPairsSectionProps> = ({
  control,
  cryptoPairsDeposit,
  cryptoPairsCashout,
}) => {
  const depositOptions = useMemo(
    () => cryptoPairsDeposit.map((value) => ({ value, label: value })),
    [cryptoPairsDeposit],
  );

  const cashoutOptions = useMemo(
    () => cryptoPairsCashout.map((value) => ({ value, label: value })),
    [cryptoPairsCashout],
  );

  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle>Currency pairs</FormSectionTitle>
      </Grid>

      <Grid item xs={10}>
        <Controller
          name="cryptoPairsDeposit"
          control={control}
          render={({ field }) => <MultiSelect {...field} label="Deposit currency pairs" options={depositOptions} />}
        />
      </Grid>

      <Grid item xs={10}>
        <Controller
          name="cryptoPairsCashout"
          control={control}
          render={({ field }) => <MultiSelect {...field} label="Cashout currency pairs" options={cashoutOptions} />}
        />
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
