import { DEFAULT_PAGINATION } from 'constants/pagination';
import { DEFAULT_CASHFLOWS_FILTERS } from 'constants/cashflows-search-params';

import { requestsFactory } from 'redux-requests-factory';
import type {
  CashflowResponse,
  CashflowsFiltersResponse,
  CashflowsRequestParams,
  CashflowsResponse,
  CreateCashflowRequest,
  UpdateCashflowRequest,
} from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getCashflows = (params: CashflowsRequestParams = {}): Promise<CashflowsResponse> =>
  axiosInstance.get(ENDPOINTS.CASHFLOWS, { params });

export const {
  loadDataAction: loadCashflowsAction,
  forcedLoadDataAction: forceLoadCashflowsAction,
  responseSelector: cashflowsResponseSelector,
  setResponseAction: setCashflowsResponse,
} = requestsFactory({
  request: getCashflows,
  stateRequestKey: 'cashflows',
  transformResponse: (response?: CashflowsResponse) => response ?? { cashflows: [], pagination: DEFAULT_PAGINATION },
});

const getCashflowsFilters = (): Promise<CashflowsFiltersResponse> => axiosInstance.get(ENDPOINTS.CASHFLOWS_FILTERS);

export const {
  loadDataAction: loadCashflowsFiltersAction,
  forcedLoadDataAction: forceLoadCashflowsFiltersAction,
  responseSelector: cashflowsFiltersResponseSelector,
} = requestsFactory({
  request: getCashflowsFilters,
  stateRequestKey: 'cashflows-filters',
  includeInGlobalLoading: false,
  transformResponse: (response?: CashflowsFiltersResponse) => response ?? DEFAULT_CASHFLOWS_FILTERS,
});

export const getCashflow = (cashflowId: number): Promise<CashflowResponse> => {
  return axiosInstance.get(ENDPOINTS.CASHFLOW(cashflowId));
};

export const {
  forcedLoadDataAction: forceLoadCashflowAction,
  responseSelector: cashflowResponseSelector,
  isLoadingSelector: isCashflowLoadingSelector,
  resetRequestAction: resetCashflowRequestAction,
} = requestsFactory({
  request: getCashflow,
  stateRequestKey: 'cashflow',
});

const createCashflow = (cashflow: CreateCashflowRequest): Promise<CashflowsResponse> => {
  return axiosInstance.post(ENDPOINTS.CREATE_CASHFLOW, cashflow);
};

export const {
  doRequestAction: createCashflowAction,
  requestFulfilledAction: createCashflowFulfilledAction,
  resetRequestAction: resetCreateCashflowAction,
  isLoadedSelector: isCreateCashflowCompletedSelector,
} = requestsFactory({
  request: createCashflow,
  stateRequestKey: 'cashflow-create',
});

const updateCashflow = (cashflow: UpdateCashflowRequest): Promise<CashflowResponse> => {
  return axiosInstance.put(ENDPOINTS.UPDATE_CASHFLOW(cashflow.id), cashflow);
};

export const {
  doRequestAction: updateCashflowAction,
  requestFulfilledAction: updateCashflowFulfilledAction,
  resetRequestAction: resetUpdateCashflowAction,
  isLoadedSelector: isUpdateCashflowCompletedSelector,
} = requestsFactory({
  request: updateCashflow,
  stateRequestKey: 'cashflow-update',
});

const resyncCashflow = (cashflowId: number): Promise<CashflowResponse> =>
  axiosInstance.post(ENDPOINTS.RESYNC_CASHFLOW(cashflowId));

export const {
  doRequestAction: resyncCashflowAction,
  requestFulfilledAction: resyncCashflowFulfilledAction,
  requestRejectedAction: resyncCashflowRejectedAction,
} = requestsFactory({
  request: resyncCashflow,
  stateRequestKey: 'cashflow-resync',
  includeInGlobalLoading: false,
});
