import { cashflowsFiltersResponseSelector, cashflowsResponseSelector } from 'api/cashflows';
import { createSelector } from 'reselect';
import { getCashflowsFiltersOptions } from 'utils/cashflows-filter-helper';

export const cashflowsSelector = createSelector(cashflowsResponseSelector, (cashflowsResponse) => {
  return cashflowsResponse.cashflows;
});

export const cashflowsPaginationSelector = createSelector(cashflowsResponseSelector, (cashflowsResponse) => {
  return cashflowsResponse.pagination;
});

export const cashflowsFiltersSelector = createSelector(cashflowsFiltersResponseSelector, (cashflowsFiltersResponse) => {
  return getCashflowsFiltersOptions(cashflowsFiltersResponse);
});
