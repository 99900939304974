export const filterCryptoPairs = (pairs: string[], currencies: string[], allCurrenciesSelected: boolean = false) => {
  if (allCurrenciesSelected) {
    return pairs;
  }

  if (currencies.length === 0 && pairs.length === 0) {
    return [];
  }

  const currencySet = new Set(currencies);

  return pairs.filter((pair) => {
    const [fromCurrency, toCurrency] = pair.split(' -> ');
    return currencySet.has(fromCurrency) && currencySet.has(toCurrency);
  });
};
