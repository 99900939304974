import { RESYNC_INTERVAL, RESYNC_LIMIT_COUNT, RETRY_COUNT, SyncStatus } from 'constants/sync-status';

import { combineEpics, type Epic } from 'redux-observable';
import { map, mergeMap, takeWhile, last, filter, switchMap, concatMap, delay } from 'rxjs/operators';
import { of, range } from 'rxjs';
import type { RootAction } from 'types/sotre/actions/root';
import type { RootState } from 'types/sotre/state/root';
import { isActionOf } from 'typesafe-actions';
import type { CashflowsResponse } from 'types/api';
import {
  cashflowsResponseSelector,
  createCashflowFulfilledAction,
  getCashflow,
  resyncCashflowAction,
  resyncCashflowFulfilledAction,
  resyncCashflowRejectedAction,
  setCashflowsResponse,
  updateCashflowFulfilledAction,
} from 'api/cashflows';

import { getCashflowAction } from './actions';

const resyncCashflowEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resyncCashflowAction)),
    map(({ payload: id }) => {
      const response = cashflowsResponseSelector(state$.value);

      return updateCashflowStatus(response, id, SyncStatus.SYNC_RETRYING);
    }),
  );

const resyncCashflowSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resyncCashflowFulfilledAction)),
    mergeMap(({ payload: { response } }) => {
      const currentResponse = cashflowsResponseSelector(state$.value);
      const updatedCashflows = currentResponse.cashflows.map((cashflow) => {
        if (cashflow.id === response.id) {
          return { ...cashflow, status: response.status };
        }

        return cashflow;
      });

      const newResponse = {
        ...currentResponse,
        cashflows: updatedCashflows,
      };

      const setResponseAction = setCashflowsResponse({ response: newResponse });
      const loadCashflowAction = getCashflowAction(response.id);

      return [setResponseAction, loadCashflowAction];
    }),
  );

const resyncCashflowFailEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resyncCashflowRejectedAction)),
    map(({ payload: { params: id } }) => {
      const response = cashflowsResponseSelector(state$.value);

      return updateCashflowStatus(response, id, SyncStatus.NON_SYNCED);
    }),
  );

const getCashflowEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getCashflowAction)),
    mergeMap((action) =>
      range(0, RESYNC_LIMIT_COUNT).pipe(
        concatMap((value) =>
          of(value).pipe(delay(RESYNC_INTERVAL + Math.floor(value / RETRY_COUNT) * RESYNC_INTERVAL)),
        ),
        switchMap(() => getCashflow(action.payload)),
        takeWhile((response) => response.status === SyncStatus.SYNC_RETRYING, true),
        last(),
        map((response) => {
          const currentResponse = cashflowsResponseSelector(state$.value);
          const updatedCashflows = currentResponse.cashflows.map((cashflow) => {
            if (cashflow.id === response.id) {
              return { ...cashflow, status: response.status, error: response.error };
            }

            return cashflow;
          });

          const newResponse = {
            ...currentResponse,
            cashflows: updatedCashflows,
          };

          return setCashflowsResponse({ response: newResponse });
        }),
      ),
    ),
  );

const updateCashflowStatus = (currentResponse: CashflowsResponse, id: number, status: SyncStatus) => {
  const newResponse = {
    ...currentResponse,
    cashflows: currentResponse.cashflows.map((cashflow) => {
      if (cashflow.id === id) {
        return { ...cashflow, status };
      }
      return cashflow;
    }),
  };

  return setCashflowsResponse({ response: newResponse });
};

const updateCashflowSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateCashflowFulfilledAction)),
    map(({ payload: { response } }) => {
      return getCashflowAction(response.id);
    }),
  );

const createCashflowSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(createCashflowFulfilledAction)),
    mergeMap(({ payload: { response } }) => {
      const actions = response.cashflows.map((cashflow) => getCashflowAction(cashflow.id));
      return actions;
    }),
  );

export default combineEpics(
  resyncCashflowEpic,
  resyncCashflowSuccessEpic,
  resyncCashflowFailEpic,
  getCashflowEpic,
  updateCashflowSuccessEpic,
  createCashflowSuccessEpic,
);
