import { Grid } from '@mui/material';
import FormDivider from 'components/atoms/FormDivider';
import React from 'react';
import type { FC } from 'react';
import type { Control, FieldError, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { Casino, PaymentSystem } from 'types/api';
import FormSelect from 'components/atoms/FormSelect';
import { FormSectionTitle } from 'components/molecules/FormContainer';
import { MultiSelect } from 'components/atoms/MultiSelect';

interface CasinoSectionProps {
  control: Control<PaymentSystem>;
  casinos: Casino[];
  isEditMode: boolean;
  errors: FieldErrors<PaymentSystem>;
  onCasinosChange: (casinoIds: number[]) => void;
}

export const CasinoSection: FC<CasinoSectionProps> = ({ control, casinos, isEditMode, errors, onCasinosChange }) => {
  const casinoLabel = isEditMode ? 'Casino' : 'Casinos';
  const options = casinos.map((c) => ({ value: c.id, label: c.codename, disabled: false }));

  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle>{casinoLabel}</FormSectionTitle>
      </Grid>

      <Grid item xs={10}>
        <Controller
          name="casinos"
          control={control}
          render={({ field }) =>
            isEditMode ? (
              <FormSelect {...field} value={field.value?.[0]} label={casinoLabel} options={options} disabled required />
            ) : (
              <MultiSelect
                {...field}
                label={casinoLabel}
                options={options}
                onChange={onCasinosChange}
                required
                error={errors.casinos as FieldError}
                showSelectFilteredOptions
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
