import { Typography } from '@mui/material';
import React, { type FC, type PropsWithChildren } from 'react';

import { TableHeaderItemContainer } from './Table.styles';

export const TableHeaderItem: FC<PropsWithChildren<object>> = ({ children }) => (
  <TableHeaderItemContainer>
    <Typography variant="caption" fontWeight="500">
      {children}
    </Typography>
  </TableHeaderItemContainer>
);
