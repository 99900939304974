import { INITIAL_CASHFLOW, INITIAL_CHILD, PROVIDERS_WITH_CURRENCIES_FIELDS } from 'constants/cashflow';
import { FormMode } from 'constants/form';
import { COINSPAID_PROVIDER } from 'constants/payment-system';

import { Grid, type SxProps, type Theme } from '@mui/material';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';
import { useEffect, useMemo, useRef, type FC } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { cashflowSelector, initCashflowFormAction, isSaveCashflowCompletedSelector } from 'store/cashflow-form';
import React from 'react';
import FormContainer from 'components/molecules/FormContainer';
import { zodResolver } from '@hookform/resolvers/zod';
import { paymentProvidersOptionsSelector, systemNamesOptionsSelector } from 'store/payment-providers/selectors';
import { casinosOptionsSelector } from 'store/casinos/selectors';
import {
  currenciesMultiSelectOptionsSelector,
  currenciesOptionsSelector,
  currenciesSelector,
} from 'store/currencies/selectors';
import FormButton from 'components/atoms/FormButton';
import type { Cashflow, CashflowChild } from 'types/entities';
import { originsOptionsSelector } from 'store/origins/selectors';
import { trustedLevelsOptionsSelector } from 'store/trusted-levels/selectors';
import { mapDataToCreateRequest, mapDataToUpdateRequest } from 'utils/mappers';
import { createCashflowAction, forceLoadCashflowAction, updateCashflowAction } from 'api/cashflows';
import { licensesResponseSelector } from 'api/licenses';
import { geCasinoLicensesOptions } from 'utils/licences-helper';
import { casinosResponseSelector } from 'api/casinos';
import type { MultiSelectOption } from 'components/atoms/MultiSelect';

import { GeneralSection } from './subcomponents/GeneralSection/GeneralSection';
import { CashflowNameSection } from './subcomponents/CashflowNameSection/CashflowNameSection';
import { createCashflowSchema } from './cashflow-schema';
import { CommissionsSection } from './subcomponents/CommissionsSection/CommissionsSection';

interface CashflowFormProps {
  cashflowId?: number;
  formMode: FormMode;
  setIsFormDirty: (dirty: boolean) => void;
  onClose: () => void;
}

const buttonStyles: SxProps<Theme> = { m: '12px 24px 12px auto' };

export const CashflowForm: FC<CashflowFormProps> = ({ cashflowId, formMode, setIsFormDirty, onClose }) => {
  const dispatch = useDispatch();

  const isEditMode = formMode === FormMode.Edit;
  const isCopyMode = formMode === FormMode.Copy;

  const cashflow = useSelector(cashflowSelector(isCopyMode));
  const lastChildId = useRef(0);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors, isSubmitted, isDirty, isSubmitting },
  } = useForm<Cashflow>({
    mode: 'onSubmit',
    defaultValues: INITIAL_CASHFLOW,
    values: cashflow,
    resolver: zodResolver(createCashflowSchema(isEditMode)),
  });

  const isSaveCompleted = useSelector(isSaveCashflowCompletedSelector);
  const providerNamesOptions: FormSelectOption[] = useSelector(paymentProvidersOptionsSelector);
  const currenciesOptions: MultiSelectOption<string>[] = useSelector(currenciesOptionsSelector);
  const originsOptions: MultiSelectOption<string>[] = useSelector(originsOptionsSelector);
  const trustedLevelsOptions: MultiSelectOption<string>[] = useSelector(trustedLevelsOptionsSelector);
  const currenciesMultiSelectOprions: MultiSelectOption<string>[] = useSelector(currenciesMultiSelectOptionsSelector);
  const casinosOptions = useSelector(casinosOptionsSelector);
  const currencies = useSelector(currenciesSelector);

  const licenses = useSelector(licensesResponseSelector);
  const casinos = useSelector(casinosResponseSelector);
  const licensesOptions = useMemo(() => {
    const casinoId = cashflow.casinos[0] ?? 0;
    const options = geCasinoLicensesOptions(licenses, casinos, casinoId, isEditMode);

    return options;
  }, [cashflow.casinos, licenses, casinos, isEditMode]);

  const watchedProviderName = watch('providerName', cashflow.providerName);
  const watchedPrimaryCurrency = watch('currency', cashflow.currency);
  const watchedAcceptableCurrencies = watch('acceptableCurrencies', cashflow.acceptableCurrencies);
  const watchedChilds = watch('childs', cashflow.childs);

  const systemNamesOptions: FormSelectOption[] = useSelector(systemNamesOptionsSelector(watchedProviderName));
  const acceptableCurrenciesOprions = currenciesMultiSelectOprions.map((option) => ({
    ...option,
    disabled: option.value === watchedPrimaryCurrency,
  }));

  const commissionsCurrenciesOptions: MultiSelectOption<string>[] = watchedAcceptableCurrencies.map((currency) => ({
    label: currency,
    value: currency,
  }));

  useEffect(() => {
    dispatch(initCashflowFormAction());

    if ((isEditMode || isCopyMode) && cashflowId) {
      dispatch(forceLoadCashflowAction(cashflowId));
    }
  }, [cashflowId, isEditMode, isCopyMode, dispatch]);

  useEffect(() => {
    lastChildId.current = cashflow.childs.length;
  }, [cashflow]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty, setIsFormDirty]);

  useEffect(() => {
    if (isSaveCompleted) {
      reset(undefined, { keepValues: true, keepDirty: false });
      onClose();
    }
  }, [isSaveCompleted, onClose, reset]);

  const onSubmit: SubmitHandler<Cashflow> = (data) => {
    if (isEditMode) {
      const cashflow = mapDataToUpdateRequest(data);
      dispatch(updateCashflowAction(cashflow));
    } else {
      const cashflow = mapDataToCreateRequest(data);
      dispatch(createCashflowAction(cashflow));
    }
  };

  const setChilds = (childs: CashflowChild[]) => {
    setValue('childs', childs, { shouldDirty: true });
  };

  const addChild = () => {
    const defaultCommissions = watchedChilds[0].commissions;
    const newChild = { ...INITIAL_CHILD, commissions: defaultCommissions, id: lastChildId.current };
    const childs = [...watchedChilds, newChild];
    lastChildId.current++;
    setChilds(childs);

    if (isSubmitted) {
      trigger(`childs.${childs.length - 1}`);
    }
  };

  const deleteChild = (index: number) => {
    const childs = watchedChilds.filter((_, i) => i !== index);
    setChilds(childs);
    validateChilds();
  };

  const validateChilds = () => {
    if (isSubmitted) {
      trigger('childs');
    }
  };

  const onProviderChange = (providerName: string) => {
    setValue('providerName', providerName, { shouldDirty: true, shouldValidate: isSubmitted });
    setValue('childSystemName', '', { shouldDirty: true, shouldValidate: isSubmitted });

    if (PROVIDERS_WITH_CURRENCIES_FIELDS.includes(watchedProviderName)) {
      updateDepositCurrencies([]);
    }

    if (providerName === COINSPAID_PROVIDER) {
      validateChilds();
    }
  };

  const onCurrencyChange = (newCurrency: string) => {
    setValue('currency', newCurrency, { shouldDirty: true, shouldValidate: isSubmitted });

    const newAcceptableCurrencies = watchedAcceptableCurrencies.filter(
      (currency) => currency !== watchedPrimaryCurrency,
    );

    if (!watchedAcceptableCurrencies.includes(newCurrency)) {
      newAcceptableCurrencies.push(newCurrency);
    }

    setValue('acceptableCurrencies', newAcceptableCurrencies, { shouldDirty: true, shouldValidate: isSubmitted });
  };

  const onAcceptableCurrenciesChange = (values: string[]) => {
    setValue('acceptableCurrencies', values, { shouldDirty: true, shouldValidate: isSubmitted });

    if (
      PROVIDERS_WITH_CURRENCIES_FIELDS.includes(watchedProviderName) &&
      watchedAcceptableCurrencies.length > values.length
    ) {
      updateDepositCurrencies(values);
    }
  };

  const updateDepositCurrencies = (availableCurrencies: string[]) => {
    const clearCurrecnies = availableCurrencies.length === 0;

    watchedChilds.forEach((child, index) => {
      let depositReceivedCurrencies: string[] = [];
      let depositSentCurrencies: string[] = [];

      if (!clearCurrecnies) {
        depositReceivedCurrencies = child.depositReceivedCurrencies.filter((currency) =>
          availableCurrencies.includes(currency),
        );

        depositSentCurrencies = child.depositSentCurrencies.filter((currency) =>
          availableCurrencies.includes(currency),
        );
      }

      setValue(`childs.${index}.depositReceivedCurrencies`, depositReceivedCurrencies, { shouldDirty: true });
      setValue(`childs.${index}.depositSentCurrencies`, depositSentCurrencies, { shouldDirty: true });
    });

    validateChilds();
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container rowSpacing={2} columnSpacing={2} sx={{ m: 0, width: '100%' }}>
        <CashflowNameSection control={control} errors={errors} />

        <GeneralSection
          isEditMode={isEditMode}
          control={control}
          providerNamesOptions={providerNamesOptions}
          casinosOptions={casinosOptions}
          systemNamesOptions={systemNamesOptions}
          currenciesOptions={currenciesOptions}
          acceptableCurrenciesOprions={acceptableCurrenciesOprions}
          paymentProvider={watchedProviderName}
          errors={errors}
          onProviderChange={onProviderChange}
          onAcceptableCurrenciesChange={onAcceptableCurrenciesChange}
          onCurrencyChange={onCurrencyChange}
        />

        <CommissionsSection
          isEditMode={isEditMode}
          control={control}
          childs={watchedChilds}
          originsOptions={originsOptions}
          trustedLevelsOptions={trustedLevelsOptions}
          licensesOptions={licensesOptions}
          currenciesOptions={currenciesOptions}
          commissionsCurrenciesOptions={commissionsCurrenciesOptions}
          paymentProvider={watchedProviderName}
          currencies={currencies}
          errors={errors}
          onAddChild={addChild}
          onDeleteChild={deleteChild}
          onValidateChilds={validateChilds}
        />

        <FormButton type="submit" sx={buttonStyles} disabled={isSubmitting}>{`${
          isEditMode ? 'Update' : 'Create'
        } cashflow`}</FormButton>
      </Grid>
    </FormContainer>
  );
};
