import React, { memo, useEffect, useRef, useState, type FC } from 'react';
import Box from '@mui/material/Box';
import Chip from 'components/atoms/Chip';

const debounceFunction = (cb: () => void, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => cb(), delay);
  };
};

interface PaymentSystemTagsProps {
  tags: string[];
}

const PaymentSystemTags: FC<PaymentSystemTagsProps> = ({ tags }) => {
  const containerRef = useRef<HTMLElement>(null);
  const [visibleTagsWidth, setVisibleTagsWidth] = useState(0);
  const [visibleTagsCount, setVisibleTagsCount] = useState(0);

  const handleResize = () => {
    if (containerRef.current) {
      const containerBottom = containerRef.current.getBoundingClientRect().bottom;
      const children = Array.from(containerRef.current.children);

      const visibleChildren = [];

      for (const child of children) {
        const childBottom = child.getBoundingClientRect().bottom;
        if (childBottom <= containerBottom) {
          visibleChildren.push(child);
        } else {
          break;
        }
      }

      const visibleTagsWidth = visibleChildren.reduce((acc, child) => acc + child.clientWidth + 4, 0);

      setVisibleTagsWidth(visibleTagsWidth);
      setVisibleTagsCount(visibleChildren.length);
    }
  };

  const debouncedHandleResize = debounceFunction(handleResize, 300);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize]);

  return (
    <Box position="relative" display="flex" height="100%" pr="50px">
      <Box ref={containerRef} display="flex" flexWrap="wrap">
        {tags.map((tag) => (
          <Box mr={0.5} key={tag}>
            <Chip size="small" label={tag} variant="outlined" />
          </Box>
        ))}
      </Box>
      <Box position="absolute" left={visibleTagsWidth}>
        {tags.length - visibleTagsCount > 0 && (
          <Chip size="small" label={`+${tags.length - visibleTagsCount}`} variant="outlined" />
        )}
      </Box>
    </Box>
  );
};

export default memo(PaymentSystemTags);
