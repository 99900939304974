const initAppInfo = (): void => {
  window.appInfo = {
    version: process.env.REACT_APP_VERSION,
    commitHash: process.env.REACT_APP_COMMIT_HASH,
    branch: process.env.REACT_APP_BRANCH,
    ciPipelineId: process.env.REACT_APP_CI_PIPELINE_ID,
    buildTime: process.env.REACT_APP_BUILD_TIME,
  };
};

export default initAppInfo;
