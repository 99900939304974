import { INITIAL_BULK_PAYMENT_SYSTEMS, PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY } from 'constants/payment-system';

import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Box, Grid, ToggleButtonGroup, type SelectChangeEvent } from '@mui/material';
import type { FieldError, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch, useSelector } from 'react-redux';
import { setTagsResponse, tagsResponseSelector } from 'api/tags';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';
import FormContainer, { FormSectionTitle } from 'components/molecules/FormContainer';
import { paymentProvidersOptionsSelector, systemNamesOptionsSelector } from 'store/payment-providers/selectors';
import FormButton from 'components/atoms/FormButton';
import { initBulkPaymentSystemFormAction } from 'store/bulk-payment-system-form';
import FormSelect from 'components/atoms/FormSelect/FormSelect';
import FormDivider from 'components/atoms/FormDivider';
import FormTextField from 'components/atoms/FormTextField';
import { MultiSelect } from 'components/atoms/MultiSelect';
import { casinosOptionsSelector } from 'store/casinos/selectors';
import type { PaymentSystemBulkEditModel } from 'types/entities';
import { PaymentSystemEditAction, type PaymentSystemBulkEditRequest } from 'types/api';
import { ToggleButton } from 'components/atoms/ToggleButton';
import {
  bulkUpdatePaymentSystemsAction,
  bulkUpdatePaymentSystemsErrorSelector,
  isBulkUpdateCompletedSelector,
} from 'api/payment-systems';
import { getUniqueValuesByDelimiter } from 'utils/getUniqueValuesByDelimiter';
import ConfirmationDialog, { WarningContentText } from 'components/molecules/ConfirmationDialog';
import NotificationBar from 'components/atoms/NotificationBar';

import { createBulkPaymentSystemSchema } from './bulk-payment-system-schema';

interface BulkPaymentSystemFormProps {
  setIsFormDirty: (dirty: boolean) => void;
  onClose: () => void;
}

const buttonStyles = { m: '12px 24px 12px auto' };
const gridStyles = { m: 0, width: '100%' };

const BulkPaymentSystemForm: FC<BulkPaymentSystemFormProps> = ({ setIsFormDirty, onClose }) => {
  const dispatch = useDispatch();

  const [isTagConfirmationOpen, setIsTagConfirmationOpen] = useState(false);
  const [newTag, setNewTag] = useState<string>('');
  const [isUserTag, setIsUserTag] = useState(true);
  const [isErrorNotificationOpen, setIsErrorNotificationOpen] = useState(false);

  const paymentSystem = INITIAL_BULK_PAYMENT_SYSTEMS;

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    reset,
    setError,
    formState: { isDirty, isSubmitting, isSubmitted, errors },
  } = useForm<PaymentSystemBulkEditModel>({
    mode: 'onSubmit',
    defaultValues: INITIAL_BULK_PAYMENT_SYSTEMS,
    values: paymentSystem,
    resolver: zodResolver(createBulkPaymentSystemSchema()),
  });

  const providerNames = useSelector(paymentProvidersOptionsSelector);
  const tags = useSelector(tagsResponseSelector);
  const casinos = useSelector(casinosOptionsSelector);
  const bulkUpdateError = useSelector(bulkUpdatePaymentSystemsErrorSelector);

  const watchedProviderName = watch('payment_system_name');
  const watchedUserTags = watch('tags');
  const watchedExcludedTags = watch('excluded_tags');
  const watchedPaymentGroups = watch('payment_groups');
  const watchedExcludedPaymentGroups = watch('excluded_payment_groups');

  const systemNames: FormSelectOption[] = useSelector(systemNamesOptionsSelector(watchedProviderName));
  const isBulkUpdateCompleted = useSelector(isBulkUpdateCompletedSelector);

  useEffect(() => {
    dispatch(initBulkPaymentSystemFormAction());
  }, [dispatch]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty, setIsFormDirty]);

  useEffect(() => {
    if (isBulkUpdateCompleted) {
      reset(undefined, { keepValues: false, keepDirty: false });
      onClose();
    }
  }, [isBulkUpdateCompleted, onClose, reset]);

  useEffect(() => {
    if (isSubmitted) {
      trigger(['excluded_payment_groups', 'payment_groups', 'tags', 'excluded_tags']);
    }
  }, [watchedPaymentGroups, watchedExcludedPaymentGroups, watchedUserTags, watchedExcludedTags, isSubmitted, trigger]);

  useEffect(() => {
    const bulkUpdateErrors = bulkUpdateError?.errors;

    if (typeof bulkUpdateErrors === 'string') {
      setIsErrorNotificationOpen(true);
    } else if (bulkUpdateErrors) {
      for (const [key, value] of Object.entries(bulkUpdateErrors)) {
        setError(key as keyof PaymentSystemBulkEditModel, { message: value });
      }
    }
  }, [bulkUpdateError, setError]);

  const userTags = tags.map((tag) => {
    return { value: tag, label: tag, disabled: watchedExcludedTags.includes(tag) };
  });

  const excludedTags = tags.map((tag) => {
    return { value: tag, label: tag, disabled: watchedUserTags.includes(tag) };
  });

  const onProviderChange = (event: SelectChangeEvent<string>) => {
    const providerName = event.target.value;
    setValue('payment_system_name', providerName, { shouldDirty: true, shouldValidate: isSubmitted });
    setValue('child_system_name', '', { shouldDirty: true, shouldValidate: isSubmitted });
  };

  const onSubmit: SubmitHandler<PaymentSystemBulkEditModel> = (data) => {
    const request: PaymentSystemBulkEditRequest = {
      operation: data.action,
      casino_ids: data.casino_ids,
      child_system_name: data.child_system_name,
      excluded_payment_groups: getUniqueValuesByDelimiter(data.excluded_payment_groups),
      payment_groups: getUniqueValuesByDelimiter(data.payment_groups),
      excluded_tags: data.excluded_tags,
      payment_system_name: data.payment_system_name,
      tags: data.tags,
    };

    dispatch(bulkUpdatePaymentSystemsAction(request));
  };

  const handleAddNewTag = (tag: string, isUserTag = true) => {
    setNewTag(tag);
    setIsUserTag(isUserTag);
    setIsTagConfirmationOpen(true);
  };

  const handleTagCreationConfirm = () => {
    onAddNewTag(newTag, isUserTag);
    setIsTagConfirmationOpen(false);
  };

  const handleTagCreationCancel = () => {
    setIsTagConfirmationOpen(false);
  };

  const handleErrorNotificationClose = () => setIsErrorNotificationOpen(false);

  const onAddNewTag = (newTag: string, isUserTags = true) => {
    const sortedTags = [...tags, newTag].sort();
    dispatch(setTagsResponse({ response: sortedTags }));

    if (isUserTags) {
      setValue('tags', [...watchedUserTags, newTag], { shouldDirty: true });
    } else {
      setValue('excluded_tags', [...watchedExcludedTags, newTag], { shouldDirty: true });
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container rowSpacing={2} columnSpacing={2} sx={gridStyles}>
        <Grid item xs={5}>
          <Controller
            name="payment_system_name"
            control={control}
            render={({ field }) => (
              <FormSelect
                label="System name"
                {...field}
                options={providerNames}
                required
                onChange={onProviderChange}
                error={errors.payment_system_name}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormDivider />
        </Grid>

        <Grid item xs={12}>
          <FormSectionTitle>General</FormSectionTitle>
        </Grid>

        <Grid item xs={5}>
          <Controller
            name="child_system_name"
            control={control}
            render={({ field }) =>
              PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY.includes(watchedProviderName) ? (
                <FormTextField {...field} label="Child system" error={errors.child_system_name} required />
              ) : (
                <FormSelect
                  {...field}
                  label="Child system"
                  options={systemNames}
                  error={errors.child_system_name}
                  required
                />
              )
            }
          />
        </Grid>

        <Grid item xs={12}>
          <FormDivider />
        </Grid>

        <Grid item xs={12}>
          <FormSectionTitle>Casinos</FormSectionTitle>
        </Grid>

        <Grid item xs={10}>
          <Controller
            name="casino_ids"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="Casinos"
                options={casinos}
                error={errors.casino_ids as FieldError}
                required
                showSelectFilteredOptions
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormDivider />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" gap={10} alignItems="center">
            <FormSectionTitle>Apply batch action</FormSectionTitle>
            <Controller
              name="action"
              control={control}
              render={({ field }) => (
                <ToggleButtonGroup {...field} exclusive>
                  <ToggleButton value={PaymentSystemEditAction.Add}>Add</ToggleButton>
                  <ToggleButton value={PaymentSystemEditAction.Remove}>Remove</ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="User tags"
                options={userTags}
                showAddOption
                onAddOption={handleAddNewTag}
                error={errors.tags as FieldError}
              />
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Controller
            name="excluded_tags"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label="User tags exclusion"
                options={excludedTags}
                showAddOption
                onAddOption={(newTag) => handleAddNewTag(newTag, false)}
                error={errors.excluded_tags as FieldError}
              />
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Controller
            name="payment_groups"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormTextField {...field} label="Payment groups" multiline error={errors.payment_groups} />
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Controller
            name="excluded_payment_groups"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormTextField
                {...field}
                label="Payment groups exclusion"
                multiline
                error={errors.excluded_payment_groups}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormDivider />
        </Grid>

        <FormButton type="submit" sx={buttonStyles} disabled={isSubmitting}>
          Update payment systems
        </FormButton>
      </Grid>

      <ConfirmationDialog
        isOpen={isTagConfirmationOpen}
        onClose={handleTagCreationCancel}
        title={`You are about to create new tag: ${newTag}.`}
        confirmButtonText="Create"
        cancelButtonText="Cancel"
        onConfirm={handleTagCreationConfirm}
        onCancel={handleTagCreationCancel}
      >
        <WarningContentText>Tags cannot be deleted once created. Please confirm your choice.</WarningContentText>
      </ConfirmationDialog>

      <NotificationBar type="error" isOpen={isErrorNotificationOpen} onClose={handleErrorNotificationClose}>
        {bulkUpdateError?.errors}
      </NotificationBar>
    </FormContainer>
  );
};

export default BulkPaymentSystemForm;
