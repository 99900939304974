import { IconName } from 'constants/icon-name';

import React, { useState } from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton, Tooltip } from '@mui/material';
import SvgIcon from 'components/atoms/SvgIcon';
import { MultiSelect, type MultiSelectOption } from 'components/atoms/MultiSelect';

import { Wrapper } from './FiltersBar.styles';

export interface FilterConfig {
  id: string;
  label: string;
  options: MultiSelectOption<string>[];
}

interface FiltersBarProps {
  filters: Record<string, FilterConfig>;
  defaultFilters: Record<string, string[]>;
  onSubmit: (result: Record<string, string[]>) => void;
  onResetFilters: () => void;
}

const multiSelectSx = { width: 160 };

const FiltersBar: FC<FiltersBarProps> = ({ filters, defaultFilters, onSubmit, onResetFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>(defaultFilters);

  const handleFilter = () => {
    const result = Object.keys(filters).reduce<Record<string, string[]>>((acc, key) => {
      const filterConfig = filters[key];
      const selectedValues = selectedFilters[key];

      return {
        ...acc,
        [key]: !selectedValues || selectedValues?.length === filterConfig.options.length ? [] : selectedValues,
      };
    }, {});
    onSubmit(result);
  };

  const handleReset = () => {
    setSelectedFilters({});
    onResetFilters();
  };

  const handleChange = (key: string) => (values: string[]) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [key]: values,
    }));
  };

  return (
    <Wrapper>
      <Box display="flex" gap="16px" alignItems="center">
        {Object.keys(filters).map((key) => {
          const filterConfig = filters[key];
          const value = selectedFilters[key] || filterConfig.options.map((o) => o.value);

          return (
            <MultiSelect
              key={filterConfig.id}
              label={filterConfig.label}
              options={filterConfig.options}
              value={value}
              limitTags
              sx={multiSelectSx}
              onChange={handleChange(key)}
            />
          );
        })}
        <Box ml="auto" display="flex" alignItems="center" gap="16px">
          <Button variant="outlined" onClick={handleFilter}>
            Filter
          </Button>
          <Tooltip arrow title="Reset filters">
            <IconButton onClick={handleReset}>
              <SvgIcon name={IconName.ResetFilters} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default FiltersBar;
