import styled from 'styled-components';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.text.dark};
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  & .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.background.style2};
  }

  & .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .MuiInputBase-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.text.disabledControl};
    background-color: ${({ theme }) => theme.palette.background.disabledControl};
  }
`;
