import { Typography } from '@mui/material';
import styled from 'styled-components';

const FormContainer = styled.form`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const FormSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 6px 0;
  font-weight: 500;
`;

export default FormContainer;
