import { Box } from '@mui/material';
import FormTextField from 'components/atoms/FormTextField';
import { MultiSelect, type MultiSelectOption } from 'components/atoms/MultiSelect';
import React from 'react';
import type { FC } from 'react';
import type { Control, ControllerRenderProps, FieldError, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { Cashflow, CashflowChild } from 'types/entities';

interface CashflowChildSectionProps {
  isEditMode: boolean;
  control: Control<Cashflow>;
  index: number;
  originsOptions: MultiSelectOption<string>[];
  trustedLevelsOptions: MultiSelectOption<string>[];
  licensesOptions: MultiSelectOption<string>[];
  currenciesOptions: MultiSelectOption<string>[];
  showCurrenciesFields: boolean;
  childs: CashflowChild[];
  errors: FieldErrors<Cashflow>;
  onValidateChilds: () => void;
}

const fieldWidth = 'calc(50% - 8px)';

export const CashflowChildSection: FC<CashflowChildSectionProps> = ({
  isEditMode,
  control,
  index,
  originsOptions,
  trustedLevelsOptions,
  licensesOptions,
  currenciesOptions,
  showCurrenciesFields,
  childs,
  errors,
  onValidateChilds,
}) => {
  const childError = errors.childs?.[index];

  const handleChange = (field: ControllerRenderProps<Cashflow>) => (value: string[]) => {
    field.onChange(value);
    onValidateChilds();
  };

  return (
    <Box display="flex" rowGap={3} gap={2} flexWrap="wrap" width="100%" pt={3}>
      <Box width="100%" display="flex" gap={2}>
        <Box width={fieldWidth}>
          <Controller
            name={`childs.${index}.optionChildSystemName`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormTextField
                {...field}
                label="Option child system name"
                error={childError?.optionChildSystemName}
                required
                onChange={handleChange(field)}
              />
            )}
          />
        </Box>

        {isEditMode && (
          <Box width={fieldWidth}>
            <Controller
              name={`childs.${index}.licenses`}
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  label="Licenses"
                  options={licensesOptions}
                  error={childError?.licenses as FieldError}
                  onChange={handleChange(field)}
                />
              )}
            />
          </Box>
        )}
      </Box>

      <Box width={fieldWidth}>
        <Controller
          name={`childs.${index}.origins`}
          control={control}
          render={({ field }) => (
            <MultiSelect
              {...field}
              label="Origins"
              options={originsOptions}
              error={childError?.origins as FieldError}
              onChange={handleChange(field)}
            />
          )}
        />
      </Box>

      <Box width={fieldWidth}>
        <Controller
          name={`childs.${index}.trustedLevels`}
          control={control}
          render={({ field }) => (
            <MultiSelect
              {...field}
              label="Trusted levels"
              options={trustedLevelsOptions}
              error={childError?.trustedLevels as FieldError}
              onChange={handleChange(field)}
            />
          )}
        />
      </Box>

      {showCurrenciesFields && (
        <>
          <Box width={fieldWidth}>
            <Controller
              name={`childs.${index}.depositSentCurrencies`}
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  label="Deposit sent currency / Cashout received currency"
                  options={currenciesOptions}
                  required={childs[index].depositReceivedCurrencies.length > 0}
                  error={childError?.depositSentCurrencies as FieldError}
                  onChange={handleChange(field)}
                />
              )}
            />
          </Box>

          <Box width={fieldWidth}>
            <Controller
              name={`childs.${index}.depositReceivedCurrencies`}
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  label="Deposit received currency / Cashout sent currency"
                  options={currenciesOptions}
                  required={childs[index].depositSentCurrencies.length > 0}
                  error={childError?.depositReceivedCurrencies as FieldError}
                  onChange={handleChange(field)}
                />
              )}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
