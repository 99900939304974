import { paymentProvidersResponseSelector } from 'api/payment-providers';
import { createSelector } from 'reselect';

export const paymentProvidersSelector = createSelector(paymentProvidersResponseSelector, (response) =>
  response.map((provider) => provider.provider_name),
);

export const paymentProvidersOptionsSelector = createSelector(paymentProvidersSelector, (providers) =>
  providers.map((value) => ({ label: value, value: value })),
);

export const systemNamesByProviderSelector = (providerName: string) =>
  createSelector([paymentProvidersResponseSelector], (providersResponse) => {
    const provider = providersResponse.find((p) => p.provider_name === providerName);
    return provider ? provider.payment_systems.map((s) => s.name) : [];
  });

export const systemNamesOptionsSelector = (providerName: string) =>
  createSelector(systemNamesByProviderSelector(providerName), (systemNames) =>
    systemNames.map((name) => ({
      label: name,
      value: name,
    })),
  );
