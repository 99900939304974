import { DEFAULT_TAB_NAME, INITIAL_CHILD } from 'constants/cashflow';

import type { CashflowCommissions, CreateCashflowRequest, UpdateCashflowRequest } from 'types/api';
import type { Cashflow, CashflowChild } from 'types/entities';

const mapCasflowChildsToCommissions = (childs: CashflowChild[]): CashflowCommissions[] => {
  const result: CashflowCommissions[] = childs.map((child) => ({
    child_system: child.optionChildSystemName,
    origins: child.origins,
    licenses: child.licenses,
    trusted_levels: child.trustedLevels,
    deposit_received_cashout_sent: child.depositReceivedCurrencies,
    deposit_sent_cashout_received: child.depositSentCurrencies,
    commissions_data: child.commissions,
  }));

  return result;
};

export const mapDataToCreateRequest = (cashflow: Cashflow): CreateCashflowRequest => {
  const result: CreateCashflowRequest = {
    acceptable_currencies: cashflow.acceptableCurrencies,
    cashflow_name: cashflow.name,
    casino_ids: cashflow.casinos,
    child_system_name: cashflow.childSystemName,
    currency: cashflow.currency,
    job_name: cashflow.jobName,
    system_name: cashflow.providerName,
    commissions: mapCasflowChildsToCommissions(Object.values(cashflow.childs)),
  };

  return result;
};

export const mapDataToUpdateRequest = (cashflow: Cashflow): UpdateCashflowRequest => {
  const result: UpdateCashflowRequest = {
    id: cashflow.id,
    acceptable_currencies: cashflow.acceptableCurrencies,
    cashflow_name: cashflow.name,
    commissions: mapCasflowChildsToCommissions(Object.values(cashflow.childs)),
  };

  return result;
};

export const mapCommissionsToCasflowChilds = (commissions: CashflowCommissions[]): CashflowChild[] => {
  const result: CashflowChild[] = [];

  if (!commissions.some((commission) => commission.child_system === DEFAULT_TAB_NAME)) {
    result.push({ ...INITIAL_CHILD, optionChildSystemName: DEFAULT_TAB_NAME });
  }

  const childs = commissions
    .filter((commission) => commission.commissions_data)
    .map((commission, index) => ({
      optionChildSystemName: commission.child_system,
      origins: commission.origins ?? [],
      trustedLevels: commission.trusted_levels ?? [],
      licenses: commission.licenses ?? [],
      depositReceivedCurrencies: commission.deposit_received_cashout_sent ?? [],
      depositSentCurrencies: commission.deposit_sent_cashout_received ?? [],
      commissions: commission.commissions_data,
      id: index,
    }));

  result.push(...childs);

  return result;
};
