import React, { type FC, type PropsWithChildren } from 'react';
import { Tooltip, Typography, Box } from '@mui/material';

interface ValidationTooltipProps {
  errorMessage?: string;
}

const slotProps = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -7],
        },
      },
    ],
  },
};

const PopperProps = {
  style: {
    zIndex: 1200,
  },
};

const ValidationTooltip: FC<PropsWithChildren<ValidationTooltipProps>> = ({ errorMessage, children }) => {
  return (
    <Tooltip
      title={errorMessage && <Typography fontSize={12}>{errorMessage}</Typography>}
      arrow
      slotProps={slotProps}
      PopperProps={PopperProps}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default ValidationTooltip;
