import { PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY } from 'constants/payment-system';

import { PaymentSystemEditAction } from 'types/api';
import type { PaymentSystemBulkEditModel } from 'types/entities';
import { getUniqueValuesByDelimiter } from 'utils/getUniqueValuesByDelimiter';
import { addCustomError } from 'utils/validation-helper';
import { z } from 'zod';

const validateSystemName = (ctx: z.RefinementCtx, childSystem: string, paymentSystem: string) => {
  if (!childSystem) {
    const validationMessage = PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY.includes(paymentSystem)
      ? 'Enter child system'
      : 'Select child system';
    addCustomError(ctx, validationMessage, ['child_system_name']);
  }
};

const validatePaymentGroups = (ctx: z.RefinementCtx, paymentGroups?: string, excludedPaymentGroups?: string) => {
  if (paymentGroups && excludedPaymentGroups) {
    const paymentGroupsArray = getUniqueValuesByDelimiter(paymentGroups);
    const excludedPaymentGroupsArray = getUniqueValuesByDelimiter(excludedPaymentGroups);

    const duplicates = paymentGroupsArray.filter((group) => excludedPaymentGroupsArray.includes(group));

    if (duplicates.length > 0) {
      const message = 'Duplicate group names are not allowed';

      addCustomError(ctx, message, ['payment_groups']);
      addCustomError(ctx, message, ['excluded_payment_groups']);
    }
  }
};

const validateTagsAndGroups = (ctx: z.RefinementCtx, data: Partial<PaymentSystemBulkEditModel>) => {
  if (!data.tags?.length && !data.excluded_tags?.length && !data.payment_groups && !data.excluded_payment_groups) {
    const message = 'Fill at least one field to update';
    addCustomError(ctx, message, ['tags']);
    addCustomError(ctx, message, ['excluded_tags']);
    addCustomError(ctx, message, ['payment_groups']);
    addCustomError(ctx, message, ['excluded_payment_groups']);
  }
};

export const baseBulkPaymentSystemSchema = z.object({
  payment_system_name: z.string().min(1, 'Select payment system'),
  child_system_name: z.string(),
  casino_ids: z.array(z.number()).min(1, 'Select casino name'),
  tags: z.array(z.string()).optional(),
  excluded_tags: z.array(z.string()).optional(),
  payment_groups: z.string().optional(),
  excluded_payment_groups: z.string().optional(),
  action: z.enum([PaymentSystemEditAction.Add, PaymentSystemEditAction.Remove]),
});

export const createBulkPaymentSystemSchema = () => {
  const schema = baseBulkPaymentSystemSchema;

  return schema.superRefine((data, ctx) => {
    validateSystemName(ctx, data.child_system_name, data.payment_system_name);
    validatePaymentGroups(ctx, data.payment_groups, data.excluded_payment_groups);
    validateTagsAndGroups(ctx, data);
  });
};
