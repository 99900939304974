import { requestsFactory } from 'redux-requests-factory';
import type { Licenses } from 'types/api/licenses';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getLicenses = (): Promise<Licenses> => axiosInstance.get(ENDPOINTS.LICENSES);

export const { loadDataAction: loadLicensesAction, responseSelector: licensesResponseSelector } = requestsFactory({
  request: getLicenses,
  stateRequestKey: 'licenses',
  transformResponse: (response?: Licenses) => response ?? {},
});
