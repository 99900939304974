import styled from 'styled-components';
import Box from '@mui/material/Box';

const ActionBar = styled(Box)`
  padding: 7px 16px;
  display: flex;
  justify-content: flex-end;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  box-shadow: 0 1px 2px 0 rgba(78, 121, 135, 0.14);
`;

export default ActionBar;
