import { Button, type ButtonProps, type SxProps, type Theme } from '@mui/material';
import React, { type FC, type PropsWithChildren } from 'react';

const FormButton: FC<PropsWithChildren<ButtonProps>> = ({ children, sx, ...props }) => {
  const buttonStyles: SxProps<Theme> = {
    ...sx,
    textTransform: 'none',
  };

  return (
    <Button {...props} variant="outlined" sx={buttonStyles}>
      {children}
    </Button>
  );
};

export default FormButton;
