import React, { useState, type FC } from 'react';
import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { StyledButton } from './MenuButton.styles';

interface MenuOption {
  label: string;
  onClick: () => void;
}

interface MenuButtonProps {
  options: MenuOption[];
  buttonLabel: string;
}

const MenuButton: FC<MenuButtonProps> = ({ options, buttonLabel }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (option: MenuOption) => () => {
    option.onClick();
    handleCloseMenu();
  };

  return (
    <>
      <StyledButton onClick={handleOpenMenu} endIcon={<KeyboardArrowDownIcon />}>
        {buttonLabel}
      </StyledButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {options.map((option) => (
          <MenuItem key={option.label} onClick={handleClickOption(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
