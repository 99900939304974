import { PERCENT_LIMITS, LIMITS_COLUMNS } from 'constants/limits';
import { PERCENT_SCALE, MAX_SCALE } from 'constants/scales';

import React from 'react';
import type { FC } from 'react';
import { Box, Grid } from '@mui/material';
import type { Control, ControllerRenderProps, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { Currency, PaymentSystem, PaymentSystemLimits, Protocol } from 'types/api';
import Typography from '@mui/material/Typography';
import FormDivider from 'components/atoms/FormDivider';
import NumberInput from 'components/atoms/NumberInput';
import { MultiSelect } from 'components/atoms/MultiSelect';
import { FormSectionTitle } from 'components/molecules/FormContainer';

import {
  LimitsRow,
  LimitTypeLabel,
  LimitsHeader,
  LimitsHeaderItem,
  LimitInputWrapper,
  DefaultLimitHint,
} from './LimitsSection.styles';

interface LimitsSectionProps {
  control: Control<PaymentSystem>;
  limits: PaymentSystemLimits;
  currencies: Currency[];
  protocols: Protocol[];
  paymentProvider: string;
  errors: FieldErrors<PaymentSystem>;
  onChangeLimits: () => void;
}

const renderLimitInputs = (
  limits: PaymentSystemLimits,
  currency: string,
  key: string,
  limitType: 'deposit' | 'cashout',
  scale: number,
  control: Control<PaymentSystem>,
  errors: FieldErrors<PaymentSystem>,
  onChangeLimits: () => void,
) => {
  const handleChange = (event: React.SyntheticEvent, field: ControllerRenderProps<PaymentSystem>) => {
    field.onChange(event);
    onChangeLimits();
  };

  return (
    <LimitInputWrapper key={`limits.${currency}.${key}.${limitType}`}>
      {limits[currency][key][limitType] !== undefined && (
        <Controller
          name={`limits.${currency}.${key}.${limitType}`}
          control={control}
          defaultValue={limits[currency][key][limitType]}
          render={({ field }) => (
            <NumberInput
              {...field}
              scale={PERCENT_LIMITS.includes(key) ? PERCENT_SCALE : scale}
              error={errors.limits?.[currency]?.[key]?.[limitType]}
              showValidationTooltip={true}
              onChange={(event) => handleChange(event, field)}
            />
          )}
        />
      )}
    </LimitInputWrapper>
  );
};

export const LimitsSection: FC<LimitsSectionProps> = ({
  control,
  limits,
  currencies,
  protocols,
  paymentProvider,
  errors,
  onChangeLimits,
}) => {
  const columns = limits.default
    ? LIMITS_COLUMNS.filter((column) => Object.keys(limits.default).includes(column.key))
    : [];

  const gridTemplateColumns = `245px repeat(${columns.length}, 1fr)`;

  const renderCurrency = (currency: string) => {
    const showCashoutRow = Object.keys(limits[currency]).some((key) => limits[currency][key].cashout !== undefined);
    const selectedCurrency = currencies.find((c) => c.iso_code === currency);
    const scale = selectedCurrency?.digits_after_comma ?? MAX_SCALE;
    const currencyProtocols = protocols.find(
      (p) => p.currency === selectedCurrency?.iso_code && p.payment_provider === paymentProvider,
    )?.protocols;
    const protocolOptions =
      currencyProtocols?.map((value) => ({
        label: value,
        value,
      })) ?? [];

    return (
      <LimitsRow key={currency} display="flex" p="12px 0" flexDirection="column">
        <Box alignItems="center" display="grid" gridTemplateColumns={gridTemplateColumns}>
          <Box p="0 12px" display="flex" alignItems="center" justifyContent="space-between">
            <Box p="0 12px">
              <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {currency}
              </Typography>
            </Box>
            <LimitTypeLabel variant="caption" fontWeight="700">
              Deposit
            </LimitTypeLabel>
          </Box>
          {columns.map(({ key }) =>
            renderLimitInputs(limits, currency, key, 'deposit', scale, control, errors, onChangeLimits),
          )}
        </Box>
        <Box
          mt={showCashoutRow ? '12px' : 0}
          alignItems="center"
          display="grid"
          gridTemplateColumns={showCashoutRow ? gridTemplateColumns : '1fr'}
        >
          <Box
            p="0 12px"
            display="flex"
            alignItems="center"
            justifyContent={showCashoutRow ? 'flex-end' : 'flex-start'}
            gap={1}
          >
            {currency === 'default' && (
              <Box justifySelf="flex-start">
                <DefaultLimitHint variant="caption">
                  Default configuration adds values to any new currency
                </DefaultLimitHint>
              </Box>
            )}
            {protocolOptions.length > 0 && (
              <Controller
                name={`protocols.${currency}`}
                control={control}
                render={({ field }) => <MultiSelect {...field} label="Protocols" options={protocolOptions} limitTags />}
              />
            )}
            {showCashoutRow && (
              <LimitTypeLabel variant="caption" fontWeight="700">
                Cashout
              </LimitTypeLabel>
            )}
          </Box>
          {columns.map(({ key }) =>
            renderLimitInputs(limits, currency, key, 'cashout', scale, control, errors, onChangeLimits),
          )}
        </Box>
      </LimitsRow>
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle>Payment system limits</FormSectionTitle>
      </Grid>

      {columns.length > 0 && (
        <Box width="100%" display="flex" flexDirection="column">
          <LimitsHeader display="grid" gridTemplateColumns={gridTemplateColumns}>
            <LimitsHeaderItem>
              <Typography variant="caption" fontWeight="500">
                Currencies
              </Typography>
            </LimitsHeaderItem>
            {columns.map(({ key, label }) => (
              <LimitsHeaderItem key={key}>
                <Typography variant="caption" fontWeight="500">
                  {label}
                </Typography>
              </LimitsHeaderItem>
            ))}
          </LimitsHeader>
          {Object.keys(limits).map(renderCurrency)}
        </Box>
      )}

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
