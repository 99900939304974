import { IconName } from 'constants/icon-name';
import { COLUMN_HEADER_HEIGHT, PAGE_SIZE } from 'constants/grid';

import React from 'react';
import type { FC } from 'react';
import { gridClasses, type DataGridProps as MuiDataGridProps } from '@mui/x-data-grid';
import Pagination from 'components/molecules/Pagination';
import SvgIcon from 'components/atoms/SvgIcon';

import { StyledDataGrid } from './DataGrid.styles';

interface DataGridProps extends MuiDataGridProps {
  autoRowHeight?: boolean;
}

const DataGrid: FC<DataGridProps> = ({ autoRowHeight = false, ...props }) => {
  const gridSx = {
    [`& .${gridClasses.cell}`]: {
      py: autoRowHeight ? 0.75 : 0,
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <StyledDataGrid
      density="compact"
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: PAGE_SIZE,
          },
        },
      }}
      autoHeight
      columnHeaderHeight={COLUMN_HEADER_HEIGHT}
      pageSizeOptions={[PAGE_SIZE]}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      disableColumnResize
      getRowHeight={() => (autoRowHeight ? 'auto' : undefined)}
      sx={gridSx}
      slots={{
        pagination: Pagination,
        columnUnsortedIcon: () => <SvgIcon name={IconName.Unsorted} />,
        columnSortedAscendingIcon: () => <SvgIcon name={IconName.SortedAscending} />,
        columnSortedDescendingIcon: () => <SvgIcon name={IconName.SortedDescending} />,
      }}
      {...props}
    />
  );
};

export default DataGrid;
