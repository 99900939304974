import { PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY } from 'constants/payment-system';

import React from 'react';
import type { FC } from 'react';
import { Grid, type SelectChangeEvent } from '@mui/material';
import type { Control, FieldError, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { Cashflow } from 'types/entities';
import FormSelect from 'components/atoms/FormSelect';
import FormDivider from 'components/atoms/FormDivider';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';
import { FormSectionTitle } from 'components/molecules/FormContainer';
import FormTextField from 'components/atoms/FormTextField';
import { MultiSelect, type MultiSelectOption } from 'components/atoms/MultiSelect';

interface GeneralSectionProps {
  isEditMode: boolean;
  control: Control<Cashflow>;
  providerNamesOptions: FormSelectOption[];
  casinosOptions: MultiSelectOption<number>[];
  systemNamesOptions: FormSelectOption[];
  currenciesOptions: FormSelectOption[];
  acceptableCurrenciesOprions: MultiSelectOption<string>[];
  paymentProvider: string;
  errors: FieldErrors<Cashflow>;
  onProviderChange: (providerName: string) => void;
  onAcceptableCurrenciesChange: (values: string[]) => void;
  onCurrencyChange: (value: string) => void;
}

export const GeneralSection: FC<GeneralSectionProps> = ({
  isEditMode,
  control,
  providerNamesOptions,
  casinosOptions,
  systemNamesOptions,
  currenciesOptions,
  acceptableCurrenciesOprions,
  paymentProvider,
  errors,
  onProviderChange,
  onAcceptableCurrenciesChange,
  onCurrencyChange,
}) => {
  const handleProviderChange = (event: SelectChangeEvent<string>) => {
    onProviderChange(event.target.value);
  };

  const handleAcceptableCurrenciesChange = (values: string[]) => {
    onAcceptableCurrenciesChange(values);
  };

  const handleCurrencyChange = (event: SelectChangeEvent<string>) => {
    onCurrencyChange(event.target.value);
  };

  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle>General</FormSectionTitle>
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="casinos"
          control={control}
          render={({ field }) =>
            isEditMode ? (
              <FormSelect
                {...field}
                value={field.value?.[0]}
                label="Casino"
                options={casinosOptions}
                disabled
                required
              />
            ) : (
              <MultiSelect
                {...field}
                label="Casinos"
                options={casinosOptions}
                required
                error={errors.casinos as FieldError}
                showSelectFilteredOptions
              />
            )
          }
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="jobName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormTextField
              {...field}
              label="Job name"
              error={errors.jobName}
              multiline
              required
              disabled={isEditMode}
            />
          )}
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="providerName"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="System name"
              {...field}
              options={providerNamesOptions}
              required
              disabled={isEditMode}
              error={errors.providerName}
              onChange={handleProviderChange}
            />
          )}
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="childSystemName"
          control={control}
          render={({ field }) =>
            PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY.includes(paymentProvider) ? (
              <FormTextField
                {...field}
                label="Child system name"
                error={errors.childSystemName}
                required
                disabled={isEditMode}
              />
            ) : (
              <FormSelect
                {...field}
                label="Child system name"
                options={systemNamesOptions}
                error={errors.childSystemName}
                required
                disabled={isEditMode}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="currency"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="Currency"
              {...field}
              options={currenciesOptions}
              required
              disabled={isEditMode}
              error={errors.currency}
              onChange={handleCurrencyChange}
            />
          )}
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="acceptableCurrencies"
          control={control}
          render={({ field }) => (
            <MultiSelect
              {...field}
              label="Acceptable Currencies"
              options={acceptableCurrenciesOprions}
              required
              error={errors.acceptableCurrencies as FieldError}
              onChange={handleAcceptableCurrenciesChange}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
