import { RESYNC_INTERVAL, RESYNC_LIMIT_COUNT, RETRY_COUNT, SyncStatus } from 'constants/sync-status';

import { combineEpics, type Epic } from 'redux-observable';
import { map, mergeMap, takeWhile, mergeMapTo, last, filter, switchMap, concatMap, delay } from 'rxjs/operators';
import { of, range } from 'rxjs';
import {
  resyncPaymentSystemFulfilledAction,
  resyncPaymentSystemRejectedAction,
  paymentsSystemsResponseSelector,
  setPaymentSystemResponse,
  resyncPaymentSystemAction,
  loadFilterChildSystems,
  getPaymentSystem,
  updatePaymentSystemFulfilledAction,
  createPaymentSystemFulfilledAction,
  bulkUpdatePaymentSystemsFulfilledAction,
} from 'api/payment-systems';
import { loadFilterPaymentProviders } from 'api/payment-providers';
import { loadFilterTags } from 'api/tags';
import { loadFilterCurrencies } from 'api/currencies';
import { loadFilterStatuses } from 'api/statuses';
import type { RootAction } from 'types/sotre/actions/root';
import type { RootState } from 'types/sotre/state/root';
import { isActionOf } from 'typesafe-actions';
import type { PaymentSystemsResponse } from 'types/api';
import { loadFilterCasinos } from 'api/casinos';

import { getPaymentSystemAction, initPaymentSystemFiltersAction } from './actions';

const resyncPaymentSystemEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resyncPaymentSystemAction)),
    map(({ payload: id }) => {
      const systemsResponse = paymentsSystemsResponseSelector(state$.value);

      return updatePaymentSystemStatus(systemsResponse, id, SyncStatus.SYNC_RETRYING);
    }),
  );

const resyncPaymentSystemSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resyncPaymentSystemFulfilledAction)),
    mergeMap(({ payload: { response } }) => {
      const currentResponse = paymentsSystemsResponseSelector(state$.value);
      const updatedSystems = currentResponse.payment_systems.map((system) => {
        if (system.id === response.id) {
          return { ...system, status: response.status };
        }

        return system;
      });

      const newResponse = {
        ...currentResponse,
        payment_systems: updatedSystems,
      };

      const setResponseAction = setPaymentSystemResponse({ response: newResponse });
      const getSystemAction = getPaymentSystemAction(response.id);

      return [setResponseAction, getSystemAction];
    }),
  );

const resyncPaymentSystemFailEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resyncPaymentSystemRejectedAction)),
    map(({ payload: { params: id } }) => {
      const systemsResponse = paymentsSystemsResponseSelector(state$.value);

      return updatePaymentSystemStatus(systemsResponse, id, SyncStatus.NON_SYNCED);
    }),
  );

const getPaymentSystemEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(getPaymentSystemAction)),
    mergeMap((action) =>
      range(0, RESYNC_LIMIT_COUNT).pipe(
        concatMap((value) =>
          of(value).pipe(delay(RESYNC_INTERVAL + Math.floor(value / RETRY_COUNT) * RESYNC_INTERVAL)),
        ),
        switchMap(() => getPaymentSystem(action.payload)),
        takeWhile((response) => response.status === SyncStatus.SYNC_RETRYING, true),
        last(),
        map((response) => {
          const currentResponse = paymentsSystemsResponseSelector(state$.value);
          const updatedSystems = currentResponse.payment_systems.map((system) => {
            if (system.id === response.id) {
              return { ...system, status: response.status, error: response.error };
            }

            return system;
          });

          const newResponse = {
            ...currentResponse,
            payment_systems: updatedSystems,
          };

          return setPaymentSystemResponse({ response: newResponse });
        }),
      ),
    ),
  );

const updatePaymentSystemStatus = (currentResponse: PaymentSystemsResponse, id: number, status: SyncStatus) => {
  const newResponse = {
    ...currentResponse,
    payment_systems: currentResponse.payment_systems.map((system) => {
      if (system.id === id) {
        return { ...system, status };
      }
      return system;
    }),
  };

  return setPaymentSystemResponse({ response: newResponse });
};

const updatePaymentSystemSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updatePaymentSystemFulfilledAction)),
    map(({ payload: { response } }) => {
      return getPaymentSystemAction(response.id);
    }),
  );

const createPaymentSystemSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([createPaymentSystemFulfilledAction, bulkUpdatePaymentSystemsFulfilledAction])),
    mergeMap(({ payload: { response } }) => {
      const actions = response.payment_systems.map((paymentSystem) => getPaymentSystemAction(paymentSystem.id));
      return actions;
    }),
  );

const initPaymentSystemFiltersEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(initPaymentSystemFiltersAction)),
    mergeMapTo([
      loadFilterPaymentProviders(),
      loadFilterChildSystems(),
      loadFilterTags(),
      loadFilterCurrencies(),
      loadFilterStatuses(),
      loadFilterCasinos(),
    ]),
  );

export default combineEpics(
  resyncPaymentSystemEpic,
  resyncPaymentSystemSuccessEpic,
  resyncPaymentSystemFailEpic,
  getPaymentSystemEpic,
  initPaymentSystemFiltersEpic,
  updatePaymentSystemSuccessEpic,
  createPaymentSystemSuccessEpic,
);
