const prefix = {
  version: (version?: number) => `/api/v${version}`,
};

export const ENDPOINTS = {
  ACCESS_DATA: (provider: string) => `${prefix.version(1)}/access_data/${provider}`,
  CASHFLOWS: `${prefix.version(1)}/cashflows`,
  CASHFLOW: (cashflowId: number) => `${prefix.version(1)}/cashflows/${cashflowId}`,
  CASHFLOWS_FILTERS: `${prefix.version(1)}/cashflows/filter`,
  CREATE_CASHFLOW: `${prefix.version(1)}/cashflows`,
  UPDATE_CASHFLOW: (id: number) => `${prefix.version(1)}/cashflows/${id}`,
  RESYNC_CASHFLOW: (id: number) => `${prefix.version(1)}/cashflows/${id}/resync`,
  CREATE_CASINO_SNAPSHOTS: `/casino_snapshots/batch_create`,
  CASINOS: `${prefix.version(1)}/casinos`,
  FILTER_CASINOS: `${prefix.version(1)}/casinos/filter`,
  CURRENCIES: `${prefix.version(1)}/currencies`,
  FILTER_CURRENCIES: `${prefix.version(1)}/currencies/filter`,
  PAYMENT_SYSTEMS_LIMITS: `${prefix.version(1)}/payment_systems_limits`,
  PAYMENT_PROVIDERS: `${prefix.version(1)}/payment_providers/settings`,
  FILTER_PAYMENT_PROVIDERS: `${prefix.version(1)}/payment_providers/filter`,
  PAYMENT_SYSTEMS: `${prefix.version(1)}/payment_systems`,
  RESYNC_PAYMENT_SYSTEM: (paymentSystemId: number) => `${prefix.version(1)}/payment_systems/${paymentSystemId}/resync`,
  PAYMENT_SYSTEM: (paymentSystemId: number) => `${prefix.version(1)}/payment_systems/${paymentSystemId}`,
  UPDATE_PAYMENT_SYSTEM: (paymentSystemId: number) => `${prefix.version(1)}/payment_systems/${paymentSystemId}`,
  BULK_UPDATE_PAYMENT_SYSTEMS: `${prefix.version(1)}/payment_systems/bulk_update`,
  CREATE_PAYMENT_SYSTEM: `${prefix.version(1)}/payment_systems`,
  FILTER_PAYMENT_SYSTEMS: `${prefix.version(1)}/payment_systems/filter`,
  SNAPSHOTS: `/snapshots`,
  SAVE_SNAPSHOTS: `/snapshots`,
  FILTER_STATUSES: `${prefix.version(1)}/statuses/filter`,
  TAGS: `${prefix.version(1)}/tags`,
  FILTER_TAGS: `${prefix.version(1)}/tags/filter`,
  ORIGINS: `${prefix.version(1)}/origins`,
  TRUSTED_LEVELS: `${prefix.version(1)}/trusted_levels`,
  LICENSES: `${prefix.version(1)}/licenses`,
  CRYPTO_PAIRS: `${prefix.version(1)}/crypto_pairs`,
};
