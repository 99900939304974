import createTheme from '@mui/material/styles/createTheme';

const defaultTheme = createTheme();

export default createTheme({
  palette: {
    primary: {
      main: '#2196f3',
      contrastText: '#00000080',
    },
    text: {
      primary: '#5e6c7b', // text
      secondary: '#8092a6', // shade1
      dark: '#000000de',
      white: '#fff',
      dialogContent: '#0000008a',
      drawerTitle: '#333',
      disabledControl: '#616161',
    },
    background: {
      default: '#f2f6f9', // canvas background
      style2: '#e8ebef', // style2
      form: '#f7f9fa', // form, grid row
      panel: '#e5edf2', // panel background
      paper: '#fff',
      disabledControl: '#f5f5f5',
    },
    error: {
      main: '#f44336',
    },
    success: {
      main: '#4caf50',
    },
    warning: {
      main: '#ff9800',
    },
    icon: '#323232',
  },
  zIndex: {
    globalLoading: 1600,
  },
  components: {
    MuiChip: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
  },
  gap: (spacing: number) => parseInt(defaultTheme.spacing(spacing), 10),
});
