import { FormMode } from 'constants/form';

import React, { useCallback, useState, type FC } from 'react';
import Drawer from 'components/molecules/Drawer';
import ConfirmationDialog, { WarningContentText } from 'components/molecules/ConfirmationDialog';
import NotificationBar from 'components/atoms/NotificationBar';
import { useDispatch } from 'react-redux';
import { resetCashflowFormAction } from 'store/cashflow-form';

import { CashflowForm } from '../CashflowForm';

interface CashflowDrawerProps {
  formMode: FormMode;
  isOpen: boolean;
  cashflowId?: number;
  onClose: () => void;
  onSaveCompleted: () => void;
}

const getDrawerTitle = (formMode: FormMode) => {
  switch (formMode) {
    case FormMode.Edit:
      return 'Edit cashflow';
    case FormMode.Copy:
      return 'New copy cashflow';
    default:
      return 'New cashflow';
  }
};

const CashflowDrawer: FC<CashflowDrawerProps> = ({ formMode, isOpen, cashflowId, onClose, onSaveCompleted }) => {
  const dispatch = useDispatch();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isQuitConfirmationOpen, setIsQuitConfirmationOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const drawerTitle = getDrawerTitle(formMode);
  const isEditMode = formMode === FormMode.Edit;

  const closeForm = useCallback(() => {
    setIsFormDirty(false);
    dispatch(resetCashflowFormAction());
    onClose();
  }, [dispatch, onClose]);

  const handleQuitForm = useCallback(() => {
    setIsQuitConfirmationOpen(false);
    closeForm();
  }, [closeForm]);

  const handleKeepEditingForm = useCallback(() => {
    setIsQuitConfirmationOpen(false);
  }, []);

  const handleFormClose = useCallback(() => {
    if (isFormDirty) {
      setIsQuitConfirmationOpen(true);
    } else {
      closeForm();
    }
  }, [isFormDirty, closeForm]);

  const handleSaveComplete = useCallback(() => {
    setIsNotificationOpen(true);
    onSaveCompleted();
    closeForm();
  }, [onSaveCompleted, closeForm]);

  const handleNotificationClose = useCallback(() => {
    setIsNotificationOpen(false);
  }, []);

  return (
    <>
      <Drawer title={drawerTitle} open={isOpen} onClose={handleFormClose}>
        <CashflowForm
          cashflowId={cashflowId}
          formMode={formMode}
          setIsFormDirty={setIsFormDirty}
          onClose={handleSaveComplete}
        />
      </Drawer>

      <ConfirmationDialog
        isOpen={isQuitConfirmationOpen}
        onClose={handleKeepEditingForm}
        title={`Quit ${isEditMode ? 'editing' : 'creating'} cashflow?`}
        confirmButtonText="Quit"
        cancelButtonText={`Keep ${isEditMode ? 'editing' : 'creating'}`}
        onConfirm={handleQuitForm}
        onCancel={handleKeepEditingForm}
      >
        <WarningContentText>All entered data will be lost.</WarningContentText>
      </ConfirmationDialog>

      <NotificationBar type="success" isOpen={isNotificationOpen} onClose={handleNotificationClose}>
        {`Cashflow has been ${isEditMode ? 'updated' : 'created'} successfully`}
      </NotificationBar>
    </>
  );
};

export default CashflowDrawer;
