import { FormMode } from 'constants/form';

import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationDialog, { HighlightedContentText } from 'components/molecules/ConfirmationDialog';
import { useSearchParams } from 'react-router-dom';
import { getCashflowsRequestParams } from 'utils/cashflows-filter-helper';
import {
  forceLoadCashflowsAction,
  forceLoadCashflowsFiltersAction,
  loadCashflowsFiltersAction,
  resyncCashflowAction,
} from 'api/cashflows';
import CashflowsTable from 'components/organisms/CashflowsTable';
import CashflowDrawer from 'components/organisms/CashflowDrawer';
import { GridWrapper } from 'components/molecules/DataGrid';
import ActionBar from 'components/molecules/ActionBar';
import AddButton from 'components/atoms/AddButton';

const Cashflows: FC = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cashflowId, setCashflowId] = useState<number>();
  const [isResyncConfirmationOpen, setIsResyncConfirmationOpen] = useState(false);
  const [formMode, setFormMode] = useState(FormMode.Add);

  const loadCashflows = useCallback(() => {
    const cashflowsRequestParams = getCashflowsRequestParams(searchParams);

    dispatch(forceLoadCashflowsAction(cashflowsRequestParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    loadCashflows();
  }, [loadCashflows, dispatch]);

  useEffect(() => {
    dispatch(loadCashflowsFiltersAction());
  }, [dispatch]);

  const onResyncClick = useCallback((id: number) => {
    setCashflowId(id);
    setIsResyncConfirmationOpen(true);
  }, []);

  const handleResyncConfirmationClose = useCallback(() => {
    setIsResyncConfirmationOpen(false);
    setCashflowId(undefined);
  }, []);

  const handleResync = useCallback(() => {
    if (cashflowId) {
      dispatch(resyncCashflowAction(cashflowId));
      handleResyncConfirmationClose();
    }
  }, [cashflowId, dispatch, handleResyncConfirmationClose]);

  const onEditClick = useCallback((id: number) => {
    setCashflowId(id);
    setIsDrawerOpen(true);
    setFormMode(FormMode.Edit);
  }, []);

  const onCopyClick = useCallback((id: number) => {
    setCashflowId(id);
    setIsDrawerOpen(true);
    setFormMode(FormMode.Copy);
  }, []);

  const onAddClick = useCallback(() => {
    setIsDrawerOpen(true);
    setFormMode(FormMode.Add);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setCashflowId(undefined);
  }, []);

  const handleSaveComplete = useCallback(() => {
    loadCashflows();
    dispatch(forceLoadCashflowsFiltersAction());
  }, [dispatch, loadCashflows]);

  return (
    <>
      <GridWrapper>
        <ActionBar>
          <AddButton onClick={onAddClick}>Add cashflow</AddButton>
        </ActionBar>

        <CashflowsTable onResyncClick={onResyncClick} onEditClick={onEditClick} onCopyClick={onCopyClick} />
      </GridWrapper>

      <CashflowDrawer
        formMode={formMode}
        isOpen={isDrawerOpen}
        cashflowId={cashflowId}
        onClose={closeDrawer}
        onSaveCompleted={handleSaveComplete}
      />

      <ConfirmationDialog
        isOpen={isResyncConfirmationOpen}
        onClose={handleResyncConfirmationClose}
        title="Resync cashflow"
        confirmButtonText="Resync"
        cancelButtonText="Cancel"
        onConfirm={handleResync}
        onCancel={handleResyncConfirmationClose}
      >
        Do you want to resync <HighlightedContentText>cashflow?</HighlightedContentText>
      </ConfirmationDialog>
    </>
  );
};

export default Cashflows;
