import { DEFAULT_TAB_NAME, NEW_CHILD_NAME, PROVIDERS_WITH_CURRENCIES_FIELDS } from 'constants/cashflow';

import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Grid, type SxProps, type Theme } from '@mui/material';
import FormDivider from 'components/atoms/FormDivider';
import { FormSectionTitle } from 'components/molecules/FormContainer';
import FormButton from 'components/atoms/FormButton';
import { Tabs, type TabItem } from 'components/molecules/Tabs';
import type { Cashflow, CashflowChild } from 'types/entities';
import type { Control, FieldErrors } from 'react-hook-form';
import type { Currency } from 'types/api';
import type { MultiSelectOption } from 'components/atoms/MultiSelect';

import { CashflowChildSection } from './CashflowChildSection';
import { CommissionsTable } from './CommissionsTable';

interface CommissionsSectionProps {
  isEditMode: boolean;
  childs: CashflowChild[];
  originsOptions: MultiSelectOption<string>[];
  trustedLevelsOptions: MultiSelectOption<string>[];
  licensesOptions: MultiSelectOption<string>[];
  currenciesOptions: MultiSelectOption<string>[];
  commissionsCurrenciesOptions: MultiSelectOption<string>[];
  paymentProvider: string;
  currencies: Currency[];
  control: Control<Cashflow>;
  errors: FieldErrors<Cashflow>;
  onAddChild: () => void;
  onDeleteChild: (index: number) => void;
  onValidateChilds: () => void;
}

const newButtonStyles: SxProps<Theme> = { mr: '16px' };
const deleteButtonStyles: SxProps<Theme> = { mr: '24px' };

export const CommissionsSection: FC<CommissionsSectionProps> = ({
  isEditMode,
  childs,
  originsOptions,
  trustedLevelsOptions,
  licensesOptions,
  currenciesOptions,
  commissionsCurrenciesOptions,
  paymentProvider,
  currencies,
  control,
  errors,
  onAddChild,
  onDeleteChild,
  onValidateChilds,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  let tabs: TabItem[] = [];
  const showCurrenciesFields = PROVIDERS_WITH_CURRENCIES_FIELDS.includes(paymentProvider);
  const commissions = childs.map((child) => child.commissions);

  if (childs.length > 0) {
    tabs = childs.map((child, index) => {
      return {
        label: child.optionChildSystemName || NEW_CHILD_NAME,
        invalid: !!errors.childs?.[index],
        id: child.id,
        component: (
          <Grid container rowGap={2} columnGap={2} m={0}>
            <Grid item xs={10}>
              <Box display="flex" width="100%" flexWrap="wrap" columnGap={2}>
                {child.optionChildSystemName !== DEFAULT_TAB_NAME && (
                  <CashflowChildSection
                    isEditMode={isEditMode}
                    control={control}
                    index={index}
                    originsOptions={originsOptions}
                    trustedLevelsOptions={trustedLevelsOptions}
                    licensesOptions={licensesOptions}
                    currenciesOptions={commissionsCurrenciesOptions}
                    showCurrenciesFields={showCurrenciesFields}
                    childs={childs}
                    errors={errors}
                    onValidateChilds={onValidateChilds}
                  />
                )}
              </Box>
            </Grid>

            <CommissionsTable
              control={control}
              index={index}
              commissions={commissions[index]}
              currenciesOptions={currenciesOptions}
              currencies={currencies}
              errors={errors}
            />
          </Grid>
        ),
      };
    });
  }

  const onTabChange = (index: number) => {
    setActiveTabIndex(index);
  };

  const addNewChild = () => {
    onAddChild();
    setActiveTabIndex(tabs.length);
  };

  const deleteChild = () => {
    const newActiveTabIndex = activeTabIndex !== tabs.length - 1 ? activeTabIndex : activeTabIndex - 1;
    onDeleteChild(activeTabIndex);
    setActiveTabIndex(newActiveTabIndex);
  };

  return (
    <>
      <Grid item xs={6}>
        <FormSectionTitle>Commissions</FormSectionTitle>
      </Grid>

      <Grid item xs={6} display="flex" justifyContent="flex-end">
        <FormButton type="button" sx={newButtonStyles} onClick={addNewChild}>
          New
        </FormButton>

        <FormButton type="button" sx={deleteButtonStyles} disabled={activeTabIndex === 0} onClick={deleteChild}>
          Delete
        </FormButton>
      </Grid>

      <Grid item xs={12}>
        {tabs.length > 0 && <Tabs tabs={tabs} onTabChange={onTabChange} activeTabIndex={activeTabIndex} />}
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
