import styled from 'styled-components';
import { Chip as MuiChip } from '@mui/material';

const Chip = styled(MuiChip)`
  & .MuiChip-label {
    color: ${({ theme }) => theme.palette.text.dark};
    letter-spacing: normal;
  }
`;

export default Chip;
