import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FooterActionsWrapper, GridWrapper } from 'components/molecules/DataGrid';
import { forceLoadPaymentSystemsAction, resyncPaymentSystemAction } from 'api/payment-systems';
import AddButton from 'components/atoms/AddButton';
import ConfirmationDialog, { HighlightedContentText } from 'components/molecules/ConfirmationDialog';
import { getPaymentSystemsRequestParams } from 'utils/payment-systems-filter-helper';
import { initPaymentSystemFiltersAction } from 'store/payment-systems';
import ActionBar from 'components/molecules/ActionBar';
import MenuButton from 'components/atoms/MenuButton';
import { useSearchParams } from 'react-router-dom';
import PaymentSystemsTable from 'components/organisms/PaymentSystemsTable';
import PaymentSystemDrawer from 'components/organisms/PaymentSystemDrawer';
import BulkPaymentSystemDrawer from 'components/organisms/BulkPaymentSystemDrawer';

const PaymentSystems: FC = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
  const [paymentSystemId, setPaymentSystemId] = useState<number>();
  const [isResyncConfirmationOpen, setIsResyncConfirmationOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const loadPaymentSystems = useCallback(() => {
    const paymentSystemRequestParams = getPaymentSystemsRequestParams(searchParams);

    dispatch(forceLoadPaymentSystemsAction(paymentSystemRequestParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    loadPaymentSystems();
    dispatch(initPaymentSystemFiltersAction());
  }, [loadPaymentSystems, dispatch]);

  const onResyncClick = useCallback((id: number) => {
    setPaymentSystemId(id);
    setIsResyncConfirmationOpen(true);
  }, []);

  const handleResyncConfirmationClose = useCallback(() => {
    setIsResyncConfirmationOpen(false);
    setPaymentSystemId(undefined);
  }, []);

  const handleResync = useCallback(() => {
    if (paymentSystemId) {
      dispatch(resyncPaymentSystemAction(paymentSystemId));
      handleResyncConfirmationClose();
    }
  }, [paymentSystemId, dispatch, handleResyncConfirmationClose]);

  const onEditClick = useCallback((id: number) => {
    setPaymentSystemId(id);
    setIsDrawerOpen(true);
    setIsEditMode(true);
  }, []);

  const onAddClick = useCallback(() => {
    setIsDrawerOpen(true);
    setIsEditMode(false);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setPaymentSystemId(undefined);
  }, []);

  const handleSaveComplete = useCallback(() => {
    loadPaymentSystems();
  }, [loadPaymentSystems]);

  const openBulkDrawer = useCallback(() => {
    setIsBulkDrawerOpen(true);
  }, []);

  const closeBulkDrawer = useCallback(() => {
    setIsBulkDrawerOpen(false);
  }, []);

  const menuOptions = useMemo(
    () => [
      {
        label: 'Edit payment systems',
        onClick: openBulkDrawer,
      },
    ],
    [openBulkDrawer],
  );

  return (
    <>
      <GridWrapper>
        <ActionBar>
          <AddButton onClick={onAddClick}>Add payment system</AddButton>
        </ActionBar>
        <PaymentSystemsTable onResyncClick={onResyncClick} onEditClick={onEditClick} />
        <FooterActionsWrapper>
          <MenuButton options={menuOptions} buttonLabel={'Batch Actions'} />
        </FooterActionsWrapper>
      </GridWrapper>

      <PaymentSystemDrawer
        isEditMode={isEditMode}
        isOpen={isDrawerOpen}
        paymentSystemId={paymentSystemId}
        onClose={closeDrawer}
        onSaveCompleted={handleSaveComplete}
      />

      <BulkPaymentSystemDrawer
        isOpen={isBulkDrawerOpen}
        onClose={closeBulkDrawer}
        onSaveCompleted={handleSaveComplete}
      />

      <ConfirmationDialog
        isOpen={isResyncConfirmationOpen}
        onClose={handleResyncConfirmationClose}
        title="Resync payment system"
        confirmButtonText="Resync"
        cancelButtonText="Cancel"
        onConfirm={handleResync}
        onCancel={handleResyncConfirmationClose}
      >
        Do you want to resync <HighlightedContentText>payment system?</HighlightedContentText>
      </ConfirmationDialog>
    </>
  );
};

export default PaymentSystems;
