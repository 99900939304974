import { PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY } from 'constants/payment-system';

import React from 'react';
import type { FC } from 'react';
import { Box, Grid } from '@mui/material';
import type { Control, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { PaymentSystem } from 'types/api';
import FormSelect from 'components/atoms/FormSelect';
import FormTextField from 'components/atoms/FormTextField';
import FormDivider from 'components/atoms/FormDivider';
import FormCheckbox from 'components/atoms/FormCheckbox';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';
import { MultiSelect, type MultiSelectOption } from 'components/atoms/MultiSelect';
import { FormSectionTitle } from 'components/molecules/FormContainer';

interface GeneralSectionProps {
  control: Control<PaymentSystem>;
  systemNames: FormSelectOption[];
  userTags: MultiSelectOption<string>[];
  excludedTags: MultiSelectOption<string>[];
  paymentProvider: string;
  isEditMode: boolean;
  errors: FieldErrors<PaymentSystem>;
}

export const GeneralSection: FC<GeneralSectionProps> = ({
  control,
  systemNames,
  userTags,
  excludedTags,
  paymentProvider,
  isEditMode,
  errors,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle>General</FormSectionTitle>
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="systemName"
          control={control}
          render={({ field }) =>
            PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY.includes(paymentProvider) ? (
              <FormTextField {...field} label="Child system" error={errors.systemName} required />
            ) : (
              <FormSelect {...field} label="Child system" options={systemNames} error={errors.systemName} required />
            )
          }
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="jobName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormTextField
              {...field}
              label="Job name"
              error={errors.jobName}
              multiline
              required
              disabled={isEditMode}
            />
          )}
        />
      </Grid>

      <Box width="100%" />

      <Grid item xs={5}>
        <Controller
          name="tags.applied"
          control={control}
          render={({ field }) => <MultiSelect {...field} label="User tags" options={userTags} />}
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="tags.excluded"
          control={control}
          render={({ field }) => <MultiSelect {...field} label="User tags exclusion" options={excludedTags} />}
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="paymentGroups"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormTextField {...field} label="Payment groups" error={errors.paymentGroups} multiline />
          )}
        />
      </Grid>

      <Grid item xs={5}>
        <Controller
          name="excludedPaymentGroups"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FormTextField {...field} label="Payment groups exclusion" error={errors.excludedPaymentGroups} multiline />
          )}
        />
      </Grid>

      <Grid item xs={10}>
        <Controller
          name="restrictions"
          control={control}
          defaultValue="{}"
          render={({ field }) => (
            <FormTextField {...field} label="Restrictions" error={errors.restrictions} multiline />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="testMode"
          control={control}
          render={({ field }) => <FormCheckbox {...field} disabled={isEditMode} label="Test mode" />}
        />
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
