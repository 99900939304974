import { createStore, applyMiddleware, type StoreEnhancer } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createRequestsFactoryMiddleware } from 'redux-requests-factory';
import { composeWithDevTools } from 'redux-devtools-extension';
import isProduction from 'utils/is-production';

import rootEpic from './epics';
import rootReducer from './reducers';

const epicMiddleware = createEpicMiddleware();
const { middleware: requestsFactoryMiddleware } = createRequestsFactoryMiddleware();

const middleware = [epicMiddleware, requestsFactoryMiddleware];
let enhancer: StoreEnhancer;

if (isProduction) {
  enhancer = applyMiddleware(...middleware);
} else {
  enhancer = composeWithDevTools(applyMiddleware(...middleware));
}

export default function configureStore() {
  const store = createStore(rootReducer, enhancer);

  epicMiddleware.run(rootEpic);

  return store;
}
