import { SyncStatus } from 'constants/sync-status';

import { Box } from '@mui/material';
import StatusBadge from 'components/atoms/StatusBadge';
import SyncErrorTooltip from 'components/atoms/SyncErrorTooltip';
import type { FC } from 'react';
import type { SyncError } from 'types/api';
import React, { useMemo } from 'react';

interface SyncStatusInfoProps {
  status: SyncStatus;
  error?: SyncError;
}

const SyncStatusInfo: FC<SyncStatusInfoProps> = ({ status, error }) => {
  const showError = useMemo(() => {
    return status === SyncStatus.NON_SYNCED && error && (error.code || error.message);
  }, [status, error]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" gap="1px">
      <StatusBadge status={status} />
      {showError && error && <SyncErrorTooltip error={error} />}
    </Box>
  );
};

export default SyncStatusInfo;
