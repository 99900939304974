import { BadgeVariants } from 'constants/badge';
import { SyncStatus } from 'constants/sync-status';

export const getSyncStatusButtonText = (status: SyncStatus): string => {
  switch (status) {
    case SyncStatus.SYNCED:
      return 'Synced';
    case SyncStatus.NON_SYNCED:
      return 'Resync';
    case SyncStatus.SYNC_RETRYING:
      return 'Syncing';
  }
};

export const getStatusText = (status: string): string => {
  switch (status) {
    case SyncStatus.SYNCED:
      return 'Synced';
    case SyncStatus.NON_SYNCED:
      return 'Not synced';
    case SyncStatus.SYNC_RETRYING:
      return 'Sync retrying';
    default:
      return '';
  }
};

export const getStatusBadgeVariant = (status: SyncStatus): BadgeVariants => {
  switch (status) {
    case SyncStatus.SYNCED:
      return BadgeVariants.SUCCESS;
    case SyncStatus.NON_SYNCED:
      return BadgeVariants.ERROR;
    case SyncStatus.SYNC_RETRYING:
      return BadgeVariants.WARNING;
  }
};

export const isEntityNonSynced = (status: SyncStatus): boolean => {
  return status === SyncStatus.NON_SYNCED;
};
