import React, { useCallback, useState, type FC } from 'react';
import Drawer from 'components/molecules/Drawer';
import ConfirmationDialog, { WarningContentText } from 'components/molecules/ConfirmationDialog';
import NotificationBar from 'components/atoms/NotificationBar';
import { useDispatch } from 'react-redux';
import { resetPaymentSystemFormAction } from 'store/payment-system-form';

import { PaymentSystemForm } from '../PaymentSystemForm';

interface PaymentSystemDrawerProps {
  isEditMode: boolean;
  isOpen: boolean;
  paymentSystemId?: number;
  onClose: () => void;
  onSaveCompleted: () => void;
}

const PaymentSystemDrawer: FC<PaymentSystemDrawerProps> = ({
  isEditMode,
  isOpen,
  paymentSystemId,
  onClose,
  onSaveCompleted,
}) => {
  const dispatch = useDispatch();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isQuitConfirmationOpen, setIsQuitConfirmationOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const closeForm = useCallback(() => {
    setIsFormDirty(false);
    dispatch(resetPaymentSystemFormAction());
    onClose();
  }, [dispatch, onClose]);

  const handleQuitForm = useCallback(() => {
    setIsQuitConfirmationOpen(false);
    closeForm();
  }, [closeForm]);

  const handleKeepEditingForm = useCallback(() => {
    setIsQuitConfirmationOpen(false);
  }, []);

  const handleFormClose = useCallback(() => {
    if (isFormDirty) {
      setIsQuitConfirmationOpen(true);
    } else {
      closeForm();
    }
  }, [isFormDirty, closeForm]);

  const handleSaveComplete = useCallback(() => {
    setIsNotificationOpen(true);
    onSaveCompleted();
    closeForm();
  }, [onSaveCompleted, closeForm]);

  const handleNotificationClose = useCallback(() => {
    setIsNotificationOpen(false);
  }, []);

  return (
    <>
      <Drawer title={isEditMode ? 'Edit configuration' : 'New configuration'} open={isOpen} onClose={handleFormClose}>
        <PaymentSystemForm
          paymentSystemId={paymentSystemId}
          isEditMode={isEditMode}
          setIsFormDirty={setIsFormDirty}
          onClose={handleSaveComplete}
        />
      </Drawer>

      <ConfirmationDialog
        isOpen={isQuitConfirmationOpen}
        onClose={handleKeepEditingForm}
        title={`Quit ${isEditMode ? 'editing' : 'creating'} payment system?`}
        confirmButtonText="Quit"
        cancelButtonText={`Keep ${isEditMode ? 'editing' : 'creating'}`}
        onConfirm={handleQuitForm}
        onCancel={handleKeepEditingForm}
      >
        <WarningContentText>All entered data will be lost.</WarningContentText>
      </ConfirmationDialog>

      <NotificationBar type="success" isOpen={isNotificationOpen} onClose={handleNotificationClose}>
        {`Payment system has been ${isEditMode ? 'updated' : 'created'} successfully`}
      </NotificationBar>
    </>
  );
};

export default PaymentSystemDrawer;
